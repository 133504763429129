import {useSelector} from 'react-redux';

export function useAuth() {
    const {accessToken, tokenType, expiresIn, isFetching, isError, errorMessage} = useSelector((state) => state.auth);

    return {
        isAuth: !!accessToken,
        tokenType,
        expiresIn,
        isFetching,
        isError,
        errorMessage
    };
}