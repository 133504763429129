import * as React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import "./print-modal.css";
import {useDispatch, useSelector} from "react-redux";
import {setPrintModal} from "../../store/modals-slice";
import {usePrintInvoiceMutation, usePrintOrderMutation} from "../../store/order-api";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PrintModal = () => {
    const dispatch = useDispatch();
    const modalStatus = useSelector((state) => state.modals.print);
    const url = useSelector((state) => state.modals.modalUrl);
    const printUrl = useSelector((state) => state.modals.modalPrintUrl);
    const invoiceUrl = useSelector((state) => state.modals.modalInvoiceUrl);

    const [printOrder] = usePrintOrderMutation();
    const [printInvoice] = usePrintInvoiceMutation();

    const handleClose = () => {
        dispatch(setPrintModal());
    };

    const handlePrint = () => {
        printOrder({url: printUrl});
    };

    const handlePrintInvoice = () => {
        printInvoice({url: invoiceUrl});
    };

    return (
        <Dialog className="Print-modal"
                open={modalStatus}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Order</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                        {!!url && <iframe id="receipt" src={url} width={430} height={500}/>}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} variant="outlined" onClick={handleClose} sx={{mr: 5}}>Close</Button>
                <Button color={"primary"} variant="contained"
                        onClick={handlePrint}>Print</Button>
                {!!invoiceUrl && <Button color={"primary"} variant="contained"
                                         onClick={handlePrintInvoice}>Print invoice</Button>}
            </DialogActions>
        </Dialog>
    );
};

export default PrintModal;