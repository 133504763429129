import type {ComboDto} from "../dto/ComboDto";
import {Condition} from "./Condition";
import moment from 'moment';

export class Combo {
    id: number;
    title: string;
    description: ?string;
    price: number;
    conditions: Condition[];
    inStockTime: [];

    constructor(base?: ComboDto) {
        if (base) {
            this.id = base.id;
            this.title = base.title;
            this.description = base.description;
            this.price = base.price;
            this.inStockTime = base.in_stock_time;
            this.conditions = base.conditions
                ? base.conditions.map((data) => new Condition(data))
                : [];
        }
    }

    isActive = () => {
        if (this.inStockTime.length > 0) {
            var today = moment().format('LL');
            const timeStart = moment(today + " " + this.inStockTime[0]).unix();
            const timeEnd = moment(today + " 15:00" + this.inStockTime[1]).unix();
            const cur = moment().unix();
            if (cur > timeStart && cur < timeEnd + 1) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    };

    timeLabel = () => {
        return this.inStockTime.length > 0 ? this.inStockTime.join("-") : "All day";
    };
}
