import { createApi } from '@reduxjs/toolkit/query/react'
import {staggeredBaseQueryWithBailOut} from "../helpers/api";
import type {ComboDto} from "../dto/ComboDto";
import {Combo} from "../models/Combo";

export const combosApi = createApi({
    reducerPath: 'combosApi',
    baseQuery: staggeredBaseQueryWithBailOut,
    endpoints: (builder) => ({
        getSpecialCombos: builder.query({
            query: () => `special-combos`,
            transformResponse: (response: ComboDto[], meta, arg) => {
                return response.map((item) => new Combo(item));
            }
        }),
    }),
})

export const { useGetSpecialCombosQuery } = combosApi;
