import type {ConditionDto} from "../dto/ConditionDto";

export class Condition {
    size: number;
    toppings: number;

    constructor(base?: ConditionDto) {
        if (base) {
            this.size = base.size;
            this.toppings = base.toppings;
        }
    }
}
