import {fetchBaseQuery, retry} from "@reduxjs/toolkit/dist/query/react";

const staggeredBaseQueryWithBailOut = retry(
    async (args, api, extraOptions) => {
        const result = await fetchBaseQuery({
            baseUrl: process.env.REACT_APP_API_URL,
            prepareHeaders: (headers, {getState}) => {
                const auth = (getState()).auth;
                if (auth.accessToken) {
                    headers.set('Authorization', `${auth.tokenType} ${auth.accessToken}`);
                    headers.set('accept', '*/*');
                }
                return headers;
            },
        })(
            args,
            api,
            extraOptions
        )

        return result;
    },
    {
        maxRetries: 1,
    }
)

const getErrorMessages = (json) => {
    if (json && json.data) {
        const error = json.data.error;
        const arr = Object.keys(error).map((item) => {
            return error[item].map((i) => {
                return i;
            });
        });
        return arr.join(", ");
    }

    return "";
};

export {
    staggeredBaseQueryWithBailOut,
    getErrorMessages
}