import * as React from 'react';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';

export default function Svg({icon}) {
    return (
        <Box
            sx={{
                '& > :not(style)': {
                    m: 2,
                },
            }}
        >
            <Icon baseClassName="fa-solid" className={icon} sx={{ fontSize: 30 }} />
        </Box>
    );
}