import * as React from 'react';
import Grid from "@mui/material/Grid";
import MenuItem from "../MenuItem";
import {useGetProductsQuery} from "../../store/product-api";
import Loader from "../Loader";

const MenuItems = ({type, customize}) => {
    const {data = [], isLoading, isError} = useGetProductsQuery();

    const Items = () => {
        if(isLoading) return (<Loader/>);
        if (isError) return (<div>Error...</div>);

        return data.map((item, idx) => item)
            .filter((item) => item.categoryId === type)
            .map((item, idx) => (
                <MenuItem product={item} customize={customize} key={idx}/>
            ));
    };
    return (
        <Grid container>
            <Items/>
        </Grid>
    );
}

export default MenuItems;