import * as React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import ToppingMenu from "../ToppingMenu";
import "./topping-modal.css";
import ToppingSidebar from "../ToppingSidebar";
import {useDispatch, useSelector} from "react-redux";
import {setMakerModal, setMenuModal} from "../../store/modals-slice";
import {clear, setOffer} from "../../store/product-customization-slice";
import {addCartComboItem, addCartItem, editCartItem, updateCartComboItem, updateCartItem} from "../../store/cart-slice";
import Maker from "../Maker";
import {useState} from "react";
import {PizzaTypes} from "../enum/pizza-types";
import {Product} from "../../models/Product";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ToppingModal = () => {
    const modalStatus = useSelector((state) => state.modals.menu);
    const modalMakerStatus = useSelector((state) => state.modals.maker);
    const data = useSelector((state) => state.menuCustomization);
    const currentCartItem = useSelector((state) => state.cart.currentItem);
    const dispatch = useDispatch();
    const [type, setType] = useState(PizzaTypes.Whole);
    const pizzaPrices = useSelector((state) => state.config.prices);

    const handleClose = () => {
        setType(PizzaTypes.Whole);
        dispatch(clear());
        dispatch(editCartItem({idx: false, type: false}))
        if (modalStatus) {
            dispatch(setMenuModal());
            dispatch(setOffer({offer: 0}));
        }
        if (modalMakerStatus) {
            dispatch(setMakerModal());
        }
    };

    const productToCombo = () => {
        const combo = data.offers.filter((item) => item.id === data.offer);
        const product = new Product({
            size: data.size,
            count: 1,
        });
        product.setSizeTitle(pizzaPrices, data.size)
        return {
            combo: {...combo[0]},
            items: [{
                product: product,
                size: data.size,
                count: 1,
                selected: [...data.selected]
            }],
            pizzaPrices
        };
    };

    const handleSave = () => {
        if (currentCartItem !== false) {
            if (data.offer !== 0) {
                dispatch(updateCartComboItem(productToCombo()));
            } else {
                dispatch(updateCartItem({item: data, type, pizzaPrices}));
            }
        } else {
            if (data.offer !== 0) {
                dispatch(addCartComboItem(productToCombo()));
            } else {
                dispatch(addCartItem({item: data, type, pizzaPrices}));
            }
        }
        setType(PizzaTypes.Whole);
        dispatch(editCartItem({idx: false, type: false}))
        if (modalStatus) {
            dispatch(setMenuModal());
        }
        if (modalMakerStatus) {
            dispatch(setMakerModal());
        }
        dispatch(setOffer({offer: 0}));
    };
    return (
        <Dialog
            className="Topping-modal"
            open={modalStatus || modalMakerStatus}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>CHOOSE TOPPINGS</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} xs={8}>
                        <Maker type={type} setType={setType}/>
                        <ToppingMenu type={type}/>
                    </Grid>
                    <Grid item lg={4} md={4} xs={4}>
                        <ToppingSidebar type={type} data={data} dispatch={dispatch}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} variant="outlined" onClick={handleClose} sx={{mr: 5}}>Cancel</Button>
                <Button color={"primary"} variant="contained" onClick={handleSave}>Add to order</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ToppingModal;