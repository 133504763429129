import {createSlice} from '@reduxjs/toolkit'
import {Product} from "../models/Product";
import {Topping} from "../models/Topping";

interface ProductCustomizationState {
    selected: Topping[],
    count: number,
    size: number,
    product: Product,
    offers: [],
    offer: number,
}

const initialState: ProductCustomizationState = {
    selected: [],
    count: 1,
    size: 10,
    product: Product,
    offers: [],
    offer: 0
};

export const productCustomizationSlice = createSlice({
    name: 'menuCustomization',
    initialState: initialState,
    reducers: {
        setSelected: (state, {payload}) => {
            const product = JSON.parse(JSON.stringify(payload.product));
            state.product = product;
            state.selected = product.toppings;
        },
        setSelectedFromCart: (state, {payload}) => {
            state.product = payload.cart.product;
            state.selected = payload.cart.toppings;
            state.count = payload.cart.count;
            state.size = payload.cart.size;
        },
        addTopping: (state, {payload}) => {
            const item = payload.topping;
            const idx = state.selected.findIndex(x => (x.id === item.id && x.type === item.type));
            if (idx !== -1) {
                let count = state.selected[idx].count;
                state.selected.splice(idx, 1);
                item.count = count + 1;
            }
            state.selected.push(item);
        },
        decrementTopping: (state, {payload}) => {
            const idx = state.selected.findIndex(x => (x.id === payload.id && x.type === payload.type));
            let count = state.selected[idx].count;
            if(count !== 0) {
                if (count === 1) {
                    state.selected.splice(idx, 1);
                } else {
                    const item = {...state.selected[idx]};
                    item.count = count - 1;
                    state.selected.splice(idx, 1);
                    state.selected.push(item);
                }
            }
        },
        removeTopping: (state, {payload}) => {
            const idx = state.selected.findIndex(x => (x.id === payload.id && x.type === payload.type));
            state.selected.splice(idx, 1);
        },
        setCountTopping: (state, {payload}) => {
            const idx = state.selected.findIndex(x => x.id === payload.id && x.type === payload.type);
            state.selected[idx].count = (state.selected[idx].count === 1) ? 2 : 1;
        },
        setCount: (state, {payload}) => {
            state.count = payload.count;
        },
        setSize: (state, {payload}) => {
            state.size = payload.size;
        },
        setOffers: (state, {payload}) => {
            state.offers = payload.offers;
        },
        setOffer: (state, {payload}) => {
            state.offer = payload.offer;
        },
        clear: () => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    setSelected,
    setSelectedFromCart,
    addTopping,
    decrementTopping,
    removeTopping,
    setCountTopping,
    setCount,
    setSize,
    setOffers,
    setOffer,
    clear
} = productCustomizationSlice.actions

export default productCustomizationSlice.reducer