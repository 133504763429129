import React from 'react';
import "./maker.css";
import {PizzaTypes} from "../enum/pizza-types";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const Maker = ({type, setType}) => {
    const handleChange = (event, newAlignment) => {
        setType(newAlignment);
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton size={"large"} value={PizzaTypes.Whole}>Whole</ToggleButton>
            <ToggleButton size={"large"} value={PizzaTypes.HalfA}>First half</ToggleButton>
            <ToggleButton size={"large"} value={PizzaTypes.HalfB}>Second half</ToggleButton>
            )
        </ToggleButtonGroup>
    );
}

export default Maker;