import { RouteProps } from "react-router-dom";

import {
    SignIn,
    Dashboard
} from '../pages';

const pageRoutes: RouteProps[] = [
    { path: "/", exact: true, element: <SignIn /> },
    { path: "/signin", exact: true, element: <SignIn /> },
    { path: "/dashboard", exact: true, element: <Dashboard />}
];

export default pageRoutes;