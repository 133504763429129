import * as React from 'react';
import "./topping-order-item.css";
import {Component} from "react";
import {IconButton, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {removeTopping} from "../../store/product-customization-slice";
import {removeComboTopping} from "../../store/combo-customization-slice";

export default class ToppingOrderItem extends Component {
    render() {
        const {value, item, dispatch, modalOffer} = this.props;
        const {id, title, count, access, type} = item;

        const removeItem = () => {
            if(modalOffer){
                dispatch(removeComboTopping({id}));
            } else {
                dispatch(removeTopping({id, type}));
            }
        };

        const DeleteBtn = () => {
            if (access) {
                return (
                    <IconButton edge="end" aria-label="delete" onClick={removeItem}>
                        <DeleteIcon color="error"/>
                    </IconButton>
                )
            } else {
                return false;
            }
        };

        return (
            <ListItem value={value} key={value}
                      secondaryAction={<DeleteBtn/>}
            >
                <ListItemAvatar className="Topping-order-item-count">
                    {count} x
                </ListItemAvatar>
                <ListItemText
                    primary={title}
                />
            </ListItem>
        );
    }
}