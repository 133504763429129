import type {OrderComboItemDto} from "../dto/OrderComboItemDto";
import {Combo} from "./Combo";
import {combinedPizzaTypes, PizzaTypes} from "../components/enum/pizza-types";

export class OrderComboItem {
    combo: Combo;
    items: []

    constructor(base?: OrderComboItemDto) {
        if (base) {
            this.combo = base.combo;
            this.items = base.items;
        }
    }

    getLabel = () => {
        return this.combo.title;
    };

    getAmount = () => {
        let sum = 0;
        for (let j = 0; j < this.items.length; j++) {
            const item = this.items[j];
            const condition = this.combo.conditions[j];
            let count = condition.toppings;
            for (let i = 0; i < item.selected.length; i++) {
                const it = item.selected;
                let price = it[i].prices[`size${condition.size}`];
                if(combinedPizzaTypes.half_a.includes(it[i].type)) {
                    price /= 2;
                }
                if (count === 0) {
                    count = 0;
                    sum += it[i].count * price;
                }
                if (count) {
                    count -= it[i].count * ((it[i].type === PizzaTypes.Whole) ? 1 : 0.5);
                    if (count < 0) {
                        count = 0;
                        sum += price;
                    }
                }
            }
        }
        return this.combo.price + sum;
    };

    getItemsLabel = () => {
        const items = this.items.map((i) => {
            const WholeItems = i.selected.filter((item) => item.type === PizzaTypes.Whole).map((item) => (item.count === 1) ? item.title : `${item.count}x ${item.title}`).join(", ");
            const halfAItems = i.selected.filter((item) => item.type === PizzaTypes.HalfA).map((item) => (item.count === 1) ? item.title : `${item.count}x ${item.title}`).join(", ");
            const halfBItems = i.selected.filter((item) => item.type === PizzaTypes.HalfB).map((item) => (item.count === 1) ? item.title : `${item.count}x ${item.title}`).join(", ");

            const wholeLabel = (WholeItems.length > 0) ? `Whole: ${WholeItems}` : '';
            const halfALabel = (halfAItems.length > 0) ? `First half: ${halfAItems}` : '';
            const halfBLabel = (halfBItems.length > 0) ? `Second half: ${halfBItems}` : '';

            const top = `${wholeLabel} \n ${halfALabel} \n ${halfBLabel}`;

            return `1 stk ${(i.product) ? i.product.size : i.size} : ${top}`;
        });
        return items.join("\r\n ");
    };
}
