import * as React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import "./order-modal.css";
import Typography from "@mui/material/Typography";
import {} from "../../helpers/math"
import Receipt from "./receipt"
import {
    List,
    ListItem,
    ListItemText
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCustomerType, changeDeliveryType,
    clearCart,
} from "../../store/cart-slice";
import {cartPrepareToSend, getCustomersTotalSum} from "../../helpers/order";
import {useCreateOrderMutation} from "../../store/order-api";
import {useEffect, useState} from "react";
import InvoiceSwitch, {DeliveryTypeSwitch} from "../Switch";
import Invoice from "./invoice";
import Phone from "./phone";
import {orderSource} from "../../data";
import Discount from "./discount";
import {getErrorMessages} from "../../helpers/api";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OrderModal = ({handleClose, handlePrintOpen, handleChangeOpen}) => {
    const dispatch = useDispatch();
    const [focus, setFocus] = useState(null);
    const [createOrder, {data, error, isLoading, isSuccess, isError}] = useCreateOrderMutation();
    const modalStatus = useSelector((state) => state.modals.order);
    const modalPhoneOrderStatus = useSelector((state) => state.modals.phoneOrder);
    const cart = useSelector((state) => state.cart);
    const pizzaPrices = useSelector((state) => state.config.prices);
    const handleOrder = () => {
        createOrder(cartPrepareToSend(cart, pizzaPrices, modalPhoneOrderStatus ? orderSource.phone : orderSource.local)).unwrap();
    };
    useEffect(() => {
        if (isSuccess) {
            dispatch(clearCart());
            const isOrderNumberModal = modalPhoneOrderStatus;
            handleClose();
            if (data.receipt_url)
                handlePrintOpen(data, isOrderNumberModal)
        }
    }, [isSuccess])

    const handleChangeCustomerType = () => {
        dispatch(changeCustomerType());
    };
    const handleChangeDeliveryType = () => {
        dispatch(changeDeliveryType());
    };

    const customersTotalSum = getCustomersTotalSum(cart.customersPaymentInfo);
    const sumDiff = cart.totalPrice - customersTotalSum;
    const paidStatus = cart.customersPaymentInfo.reduce((sum, next) => sum && next.paid, true);

    const OrderModalContent = () => {
        return (<div>
            <Typography variant="h6" component="h6" sx={{ml: 2, mb: 5}}>
                <InvoiceSwitch status={cart.invoice} handleChangeCustomerType={handleChangeCustomerType}/>
            </Typography>
            {!cart.invoice && <Receipt handleChangeOpen={handleChangeOpen} cart={cart} focus={focus} setFocus={setFocus}/>}
            {!!cart.invoice && <Invoice handleChangeOpen={handleChangeOpen} cart={cart} focus={focus} setFocus={setFocus}/>}
        </div>);
    }

    const payInfo = cart.customersPaymentInfo[0];

    return (
        <Dialog className="Order-modal"
                open={modalStatus || modalPhoneOrderStatus}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Complete order</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xs={6}>
                        <Typography variant="h6" component="h6" sx={{ml: 2}}>
                            Order
                        </Typography>
                        <div className="Order-modal-order-scroll">
                            <Grid item xs={12} md={12}>
                                <List>
                                    {cart.items.map((item, idx) => {
                                        if (item.combo) {
                                            return (<ListItem key={idx} secondaryAction={`${item.getAmount()} ISK`}>
                                                <ListItemText
                                                    primary={`${item.getLabel()}`}
                                                    secondary={item.getItemsLabel()}
                                                />
                                            </ListItem>)
                                        }
                                        return (<ListItem key={idx} secondaryAction={`${item.amount} ISK`}>
                                            <ListItemText
                                                primary={`${item.getLabel()} ${item.product.title}`}
                                                secondary={item.getToppingsLabel()}
                                            />
                                        </ListItem>)
                                    })}
                                </List>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={6}>
                        <Discount cart={cart} focus={focus} setFocus={setFocus} />
                        <Typography variant="h6" component="h6" sx={{ml: 2, mb: 2}}>
                            <DeliveryTypeSwitch status={cart.deliveryType} handleChangeDeliveryType={handleChangeDeliveryType}/>
                        </Typography>
                        {!modalPhoneOrderStatus && <OrderModalContent/>}
                        {!!modalPhoneOrderStatus && <Phone cart={cart} setFocus={setFocus} focus={focus}/>}
                    </Grid>
                </Grid>
            </DialogContent>
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={6}></Grid>
                <Grid item lg={6} md={6} xs={6} sx={{minHeight: "100px"}}>
                    {
                        !!sumDiff && <Typography variant="h6" component="h6" sx={{ml: 2, color: "red"}}>
                            Error - There is a lack of {sumDiff} ISK to the whole amount
                        </Typography>
                    }
                    {
                        !!isError && <Typography variant="h6" component="h6" sx={{ml: 2, color: "red"}}>
                            Error - {getErrorMessages(error)}
                        </Typography>
                    }
                    {
                        !!cart.invoice && (payInfo.ssn.length > 0 && payInfo.ssn.length < 10) && <Typography variant="h6" component="h6" sx={{ml: 2, color: "red"}}>
                            Error - Wrong SSN/Kinnetala number
                        </Typography>
                    }
                    {
                        !!cart.invoice && (payInfo.ssn.length === 0) && <Typography variant="h6" component="h6" sx={{ml: 2, color: "red"}}>
                            Error - SSN/Kinnetala is required
                        </Typography>
                    }
                    {
                        !!cart.invoice && (payInfo.companyName.length === 0) && <Typography variant="h6" component="h6" sx={{ml: 2, color: "red"}}>
                            Error - Company Name is required
                        </Typography>
                    }
                </Grid>
            </Grid>
            <DialogActions>
                <Button color={"error"} variant="outlined" onClick={handleClose} sx={{mr: 5}}>Cancel</Button>
                <Button
                    disabled={(!modalPhoneOrderStatus && (!!isLoading || !!sumDiff || !paidStatus)) || (modalPhoneOrderStatus
                        && (cart.customersPaymentInfo[0].name.length === 0 || cart.customersPaymentInfo[0].phone.length === 0))
                    || (cart.invoice && (cart.customersPaymentInfo[0].ssn.length < 10 || cart.customersPaymentInfo[0].companyName.length === 0))}
                    color={"primary"} variant="contained"
                    onClick={handleOrder}>Order</Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrderModal;