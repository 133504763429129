import {OrderDto} from "../dto/OrderDto";
import {HistoryOrderItem} from "./HistoryOrderItem";

export class Order {
    data: [];
    page: 1;
    limit: 0;
    count: 0;
    pages: 0;
    keywords: ""

    constructor(base?: OrderDto) {
        if (base) {
            this.data = base.data
                ? base.data.map((data) => new HistoryOrderItem(data))
                : [];
            this.page = base.page;
            this.limit = base.limit;
            this.count = base.count;
            this.pages = base.pages;
            this.keywords = base.keywords;
        }
    }
}
