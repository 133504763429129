import {ToppingDto} from "../dto/ToppingDto";

export class Topping {
    id: number;
    title: string;
    categoryId: number;
    prices: [];
    count: number = 1;
    access: boolean = false;
    type: false;

    constructor(base?: ToppingDto) {
        if (base) {
            this.id = base.id;
            this.title = base.title;
            this.categoryId = base.category_id;
            this.prices = base.prices;
            this.count = base.count ? base.count : 1;
            this.access = base.access ? base.access : false;
            this.type = base.type ? base.type : false;
        }
    }

    setAccess = () => {
        this.access = true;
    }
}
