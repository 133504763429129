import * as React from 'react';
import "./topping-sidebar.css";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ToppingOrderItem from "../ToppingOrderItem";
import {Box, List, Select, TextField} from "@mui/material";
import Sizes from "../Sizes"
import Button from "@mui/material/Button";
import RemoveIcon from '@mui/icons-material/Remove';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import {setCount, setOffer} from "../../store/product-customization-slice";
import {PizzaTypes} from "../enum/pizza-types";
import MenuItem from "@mui/material/MenuItem";

const ToppingSidebar = ({type, data, dispatch}) => {
    const wholeSelected = data.selected.filter((item) => item.type === PizzaTypes.Whole);
    const halfASelected = data.selected.filter((item) => item.type === PizzaTypes.HalfA);
    const halfBSelected = data.selected.filter((item) => item.type === PizzaTypes.HalfB);

    const upValue = () => {
        const count = data.count + 1;
        dispatch(setCount({count}));
    };
    const downValue = () => {
        if (data.count !== 1) {
            const count = data.count - 1;
            dispatch(setCount({count}));
        }
    };

    const SelectedView = () => {
        if (!type) {
            return data.selected.map((item, idx) =>
                (<ToppingOrderItem key={idx} type={type} value={idx} item={item} dispatch={dispatch}/>));
        }

        if (data.selected.length === 0) {
            return null;
        }

        return (
            <div>
                <Typography variant="h5" component="h5" sx={{mt: 3, ml: 2}}>
                    Whole
                </Typography>
                {wholeSelected.map((item, idx) =>
                    (<ToppingOrderItem key={idx} type={type} value={idx} item={item} dispatch={dispatch}/>))}
                <Typography variant="h5" component="h5" sx={{mt: 3, ml: 2}}>
                    First half
                </Typography>
                {halfASelected.map((item, idx) =>
                    (<ToppingOrderItem key={idx} type={type} value={idx} item={item} dispatch={dispatch}/>))}
                <Typography variant="h5" component="h5" sx={{mt: 3, ml: 2}}>
                    Second half
                </Typography>
                {halfBSelected.map((item, idx) =>
                    (<ToppingOrderItem key={idx} type={type} value={idx} item={item} dispatch={dispatch}/>))}
            </div>
        );
    };

    const SelectOffers = () => {
        return (
            <div>
                <Typography variant="h6" component="h6" sx={{ml: 2}}>
                    Select Offer
                </Typography>
                <Box sx={{ml: 2, mt: 1}}>
                    <Select key={0} sx={{width: '100%'}}
                            labelId={`select-offer-0`}
                            id={`select-offer-0`}
                            label="Offers"
                            onChange={(event) => dispatch(setOffer({offer: event.target.value}))}
                            value={data.offer}
                    >
                        <MenuItem key={'01'} value={0}>No offer</MenuItem>
                        {data.offers.map((item, idx) => {
                            return (
                                <MenuItem key={idx} value={item.id} disabled={!item.isActive()}>
                                    {item.title} {item.description} - <b>{item.price} ISK</b><br/>
                                    {item.timeLabel()}
                                </MenuItem>)
                        })}
                    </Select>
                </Box>
            </div>
        );
    };

    return (
        <div>
            <Typography variant="h4" component="h4" sx={{mt: 3, ml: 2}}>
                {data.product.title}
            </Typography>
            <div className="topping-Sidebar-scroll">
                <Grid item xs={12} md={12}>
                    <List>
                        <SelectedView/>
                    </List>
                </Grid>
            </div>
            <Typography variant="h6" component="h6" sx={{ml: 2}}>
                Size
            </Typography>
            <Box sx={{ml: 2, mt: 1}}>
                <Sizes state={data} dispatch={dispatch}/>
            </Box>
            {data.offers.length > 0 && <SelectOffers/>}
            <Box sx={{ml: 2, mt: 4}}>
                <Button variant="contained" color={"inherit"} onClick={downValue}
                        sx={{padding: "16px"}}><RemoveIcon/></Button>
                <TextField size={"medium"} disabled inputProps={{inputMode: 'numeric'}}
                           value={data.count}
                           sx={{width: "60px", ml: 1, fontWeight: "bold"}}/>
                <Button variant="contained" color={"inherit"} onClick={upValue}
                        sx={{ml: 1, padding: "16px"}}><PlusOneIcon/></Button>
            </Box>
        </div>
    );
}

export default ToppingSidebar;