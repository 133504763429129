export const PizzaTypes = {
    Whole: 'whole',
    HalfA: 'half_a',
    HalfB: 'half_b',
}

export const combinedPizzaTypes = {
    "whole": [PizzaTypes.Whole],
    "half_a": [PizzaTypes.HalfA, PizzaTypes.HalfB],
    "half_b": [PizzaTypes.HalfA, PizzaTypes.HalfB],
}