import * as React from 'react';
import "./offer-modal-sidebar.css";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ToppingOrderItem from "../ToppingOrderItem";
import {Box, List} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {useSelector} from "react-redux";
import {PizzaTypes} from "../enum/pizza-types";

const OfferModalSidebar = ({type, data, dispatch, size, allowHalfs}) => {
    const modalOfferStatus = useSelector((state) => state.modals.offer);
    const selected = data.items[data.current].selected;
    const wholeSelected = selected.filter((item) => item.type === PizzaTypes.Whole);
    const halfASelected = selected.filter((item) => item.type === PizzaTypes.HalfA);
    const halfBSelected = selected.filter((item) => item.type === PizzaTypes.HalfB);

    const HalfsSidebar = () => {
        if(!allowHalfs) return null;

        return (
            <>
                <Typography variant="h5" component="h5" sx={{mt: 3, ml: 2}}>
                    First half
                </Typography>
                {halfASelected.map((item, idx) =>
                    (<ToppingOrderItem key={idx} type={type} value={idx} item={item} dispatch={dispatch}
                                       modalOffer={modalOfferStatus}/>))}
                <Typography variant="h5" component="h5" sx={{mt: 3, ml: 2}}>
                    Second half
                </Typography>
                {halfBSelected.map((item, idx) =>
                    (<ToppingOrderItem key={idx} type={type} value={idx} item={item} dispatch={dispatch}
                                       modalOffer={modalOfferStatus}/>))}
            </>
        );
    }

    const SelectedView = () => {
        if (selected.length === 0) {
            return null;
        }

        return (
            <div>
                <Typography variant="h5" component="h5" sx={{mt: 3, ml: 2}}>
                    Whole
                </Typography>
                {wholeSelected.map((item, idx) =>
                    (<ToppingOrderItem key={idx} type={type} value={idx} item={item} dispatch={dispatch}
                                       modalOffer={modalOfferStatus}/>))}
                <HalfsSidebar />
            </div>
        );
    };

    return (
        <div>
            <Typography variant="h4" component="h4" sx={{mt: 3, ml: 2}}>
                {data.combo.title}
            </Typography>
            <div className="offer-modal-sidebar-scroll">
                <Grid item xs={12} md={12}>
                    <List>
                        <SelectedView/>
                    </List>
                </Grid>
            </div>
            <Typography variant="h6" component="h6" sx={{ml: 2}}>
                Size
            </Typography>
            <Box sx={{ml: 2, mt: 1}}>
                <ToggleButtonGroup
                    color="primary"
                    value={data.items[data.current].size}
                    exclusive
                >
                    <ToggleButton size={"large"}
                                  value={data.items[data.current].size}>{size}</ToggleButton>
                    )}
                </ToggleButtonGroup>
            </Box>
        </div>
    );
}

export default OfferModalSidebar;