import * as React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {setOrderNumberModal} from "../../store/modals-slice";
import Typography from "@mui/material/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OrderNumberModal = () => {
    const dispatch = useDispatch();
    const modalStatus = useSelector((state) => state.modals.orderNumber);
    const orderNumber = useSelector((state) => state.modals.modalOrderNumber);

    const handleClose = () => {
        dispatch(setOrderNumberModal());
    };

    return (
        <Dialog className="Print-modal"
                open={modalStatus}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Order Number</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="h3" component="h3" sx={{ml: 2}}>
                            {orderNumber}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} variant="outlined" onClick={handleClose} sx={{mr: 5}}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrderNumberModal;