import * as React from 'react';
import Grid from '@mui/material/Grid';
import Menu from "../../components/Menu";
import Order from "../../components/Order";
import {useAuth} from "../../hooks/use-auth";
import {Navigate} from "react-router-dom";
import PrintModal from "../../components/PrintModal";
import OrderNumberModal from "../../components/OrderNumberModal";
import OrderModal from "../../components/OrderModal";
import ChangeModal from "../../components/ChangeModal";
import {useDispatch, useSelector} from "react-redux";
import {closeModals, setChangeModal, setOrderNumberModal, setPrintModal} from "../../store/modals-slice";
import {useState} from "react";
import PayModal from "../../components/PayModal";

const Dashboard = () => {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState(0);
    const [customer, setCustomer] = useState(null);

    const handleClose = () => {
        dispatch(closeModals());
    };
    const handlePrintOpen = (data, isOrderNumberModal) => {
        const url = data.receipt_url ? data.receipt_url : false;
        const invoiceUrl = data.invoice_url ? data.invoice_url : false;
        if (url) {
            dispatch(setPrintModal({url, printUrl: url, invoiceUrl}));
        }
        if (isOrderNumberModal) {
            dispatch(setOrderNumberModal({id: data.id}));
        }
    };

    const handleChangeOpen = (idx, amount) => {
        setAmount(amount);
        setCustomer(idx);
        dispatch(setChangeModal());
    };

    const modalOrderStatus = useSelector((state) => state.modals.order);
    const modalPhoneOrderStatus = useSelector((state) => state.modals.phoneOrder);
    const modalPayStatus = useSelector((state) => state.modals.pay);

    const {isAuth} = useAuth();
    if (!isAuth) {
        return (<Navigate to="/signin"/>);
    }

    return (
        <div>
            <PrintModal/>
            <OrderNumberModal/>
            <ChangeModal amount={amount} idx={customer}/>
            {(!!modalOrderStatus || !!modalPhoneOrderStatus) && <OrderModal
                handleClose={handleClose}
                handlePrintOpen={handlePrintOpen}
                handleChangeOpen={handleChangeOpen}
            />}
            {!!modalPayStatus && <PayModal
                handleClose={handleClose}
                handlePrintOpen={handlePrintOpen}
                handleChangeOpen={handleChangeOpen}
            />}
            <Grid container spacing={2}>
                <Grid item lg={8} md={8} xs={8}>
                    <Menu/>
                </Grid>
                <Grid item lg={4} md={4} xs={4}>
                    <Order/>
                </Grid>
            </Grid>
        </div>
    );
}

export default Dashboard;