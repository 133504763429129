import * as React from 'react';
import "./order-history-item.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Paper, styled} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {setPrintModal} from "../../store/modals-slice";

const GridItem = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: '60px',
}));

const lightTheme = createTheme({palette: {mode: 'light'}});

const OrderHistoryItem = ({item, handlePayOpen}) => {
    const dispatch = useDispatch();
    const {id, amount, date, time, receiptUrl, receiptPrintUrl, invoicePrintUrl, paid, customer, position} = item;

    const handlePrintOpen = () => {
        dispatch(setPrintModal({url: receiptUrl, printUrl: receiptPrintUrl, invoiceUrl: invoicePrintUrl}));
    };
    const setItemToPay = () => {
        handlePayOpen(item);
    };

    return (
        <Grid item xs={12} sm={6} md={4} padding={2} key={id}>
            <ThemeProvider theme={lightTheme}>
                <GridItem className="History-item-grid-item">
                    <Typography key={id} variant="h4" component="h4" className="History-item-title">
                        {position}
                    </Typography>
                    <Typography sx={{mt: 2}} className="History-item-description" color="text.secondary">
                        {amount} ISK
                    </Typography>
                    <Typography sx={{mt: 2}} className="History-item-description" color="text.secondary">
                        {!!customer && <span>Name - <b>{customer.name}</b></span>}
                    </Typography>
                    <Typography className="History-item-description" color="text.secondary">
                        {!!customer && <span>Phone - <b>{customer.phone}</b></span>}
                    </Typography>
                    <Typography sx={{mb: 1.5}} className="History-item-description" color="text.secondary">
                        {date} {time}
                    </Typography>
                    {!!paid && <Button type="submit"
                                       variant="outlined"
                                       fullWidth
                                       color={"primary"}
                                       size="large"
                                       sx={{mt: 1, mb: 2}}
                                       onClick={handlePrintOpen}
                    > VIEW </Button>}
                    {!paid && <Button type="submit"
                                      className="History-item-button-menu-item"
                                      fullWidth
                                      variant="contained"
                                      size="large"
                                      sx={{mt: 1, mb: 2}}
                                      onClick={setItemToPay}
                    > Order </Button>}
                </GridItem>
            </ThemeProvider>
        </Grid>
    );
}

export default OrderHistoryItem;