import * as React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import "./change-modal.css";
import {useDispatch, useSelector} from "react-redux";
import {setChangeModal} from "../../store/modals-slice";
import {changeCustomerPaid} from "../../store/cart-slice";
import {changeCustomerPaid as changeCustomerPaidPayModal} from "../../store/pay-slice";
import {FormControl, InputAdornment, OutlinedInput} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeModal = ({amount, idx}) => {
    const dispatch = useDispatch();
    const modalStatus = useSelector((state) => state.modals.change);
    const modalPayStatus = useSelector((state) => state.modals.pay);
    const [change, setChange] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);

    useEffect(() => {
        setPaidAmount(0)
        setChange(0)
    }, [modalStatus]);

    const handleClose = () => {
        dispatch(setChangeModal());
    };

    const handlePaid = (idx) => () => {
        if (modalPayStatus) {
            dispatch(changeCustomerPaidPayModal({idx, paid: true, paidAmount: paidAmount, change}));
        } else {
            dispatch(changeCustomerPaid({idx, paid: true, paidAmount: paidAmount, change}));
        }
        dispatch(setChangeModal());
    };

    const changeC = (event) => {
        setPaidAmount(event.target.value);
        setChange(event.target.value - amount);
    };

    return (
        <Dialog className="Print-modal"
                open={modalStatus}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{"z-index": 9999}}
        >
            <DialogTitle>Print receipt</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Typography variant="h6" component="h6" sx={{mt: 1, ml: 2, mb: 4}}>
                            Amount: {amount} ISK
                        </Typography>
                        <FormControl sx={{m: 1, width: 300}} variant="outlined">
                            <OutlinedInput
                                id={`outlined-adornment-weight-r-${idx}`}
                                type={"number"}
                                value={paidAmount}
                                onChange={changeC}
                                endAdornment={<InputAdornment position="end">ISK</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                            />
                        </FormControl>
                        <Typography variant="h4" component="h4" sx={{mt: 8, ml: 2, mb: 8}}>
                            Change: {change} ISK
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} variant="outlined" onClick={handleClose} sx={{mr: 5}}>Close</Button>
                <Button color={"primary"} variant="contained"
                        onClick={handlePaid(idx)}>Paid</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeModal;