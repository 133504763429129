import type {CustomerDto} from "../dto/CustomerDto";

export class Customer {
    name: null;
    phone: null;

    constructor(base?: CustomerDto) {
        if (base) {
            this.name = base.name;
            this.phone = base.phone;
        }
    }
}
