import {createSlice} from '@reduxjs/toolkit'
import {setSize} from "./product-customization-slice";
import {defaultSize, setDefaultSize} from "../data";

interface ConfigState {
    prices: [],
}

const initialState: ConfigState = {
    prices: [],
};

export const configSlice = createSlice({
    name: 'config',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        ["productApi/executeQuery/fulfilled"]: (state, {meta, payload}) => {
            if(meta.arg.endpointName === "getPrices"){
                state.prices = payload
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const {} = configSlice.actions

export default configSlice.reducer