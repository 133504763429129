import React, {useState} from 'react';
import {Tab, Box} from '@mui/material';
import {TabPanel, TabContext, TabList} from '@mui/lab';
import {tabsClasses} from '@mui/material/Tabs';
import Svg from "../Svg";
import "./topping-menu.css";
import ToppingItems from "../ToppingItems";
import {useGetToppingCategoriesQuery} from "../../store/topping-api";

const ToppingMenu = ({type}) => {
    const [value, setValue] = useState(0);
    const {data = [], isLoading, isError} = useGetToppingCategoriesQuery();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if(isLoading) return (<div>Loading...</div>);
    if(isError) return (<div>Error...</div>);

    return (
        <TabContext value={value.toString()}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList
                    allowScrollButtonsMobile
                    scrollButtons
                    variant="scrollable"
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': {opacity: 0.3},
                        },
                    }}
                    onChange={handleChange} aria-label="Toppings">
                    {data.map(({title, icon, id}, idx) => (
                            <Tab value={idx.toString()} key={idx} icon={<Svg icon={icon}/>} label={title}/>
                        )
                    )}
                </TabList>
            </Box>
            <div className="topping-Menu-scroll">
                {data.map(({title, id}, idx) => (
                        <TabPanel key={idx} value={idx.toString()}>
                            <ToppingItems type={type} id={id} />
                        </TabPanel>
                    )
                )}
            </div>
        </TabContext>
    );
}

export default ToppingMenu;