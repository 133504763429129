import * as React from 'react';
import Typography from '@mui/material/Typography';
import "./order-item.css";
import {Component} from "react";
import Grid from "@mui/material/Grid";
import {Paper, styled} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Button from "@mui/material/Button";
import RemoveOrderItem from "../Dialogs/confirm-remove-order-item";
import {editCartItem} from "../../store/cart-slice";
import {setSelectedFromCart} from "../../store/product-customization-slice";
import {setMakerModal, setMenuModal, setOfferModal} from "../../store/modals-slice";
import {setSelectedComboFromCart} from "../../store/combo-customization-slice";

const GridItem = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));
const lightTheme = createTheme({palette: {mode: 'light'}});

export default class OrderItem extends Component {
    render() {
        const {idx, dispatch, cart, order} = this.props;
        const handleOrderItemEdit = () => {
            const cartItem = cart.items[idx];
            dispatch(editCartItem({idx, type: cartItem.type}));
            if(order.combo){
                dispatch(setSelectedComboFromCart({combo: order.combo, items: order.items}));
                dispatch(setOfferModal());
            } else {
                dispatch(setSelectedFromCart({cart: cartItem}));
                if (!cartItem.type) {
                    dispatch(setMenuModal());
                } else {
                    dispatch(setMakerModal());
                }
            }
        };
        const EditOrderItem = () => {
            if (order.size || order.combo) {
                return (<Button variant="contained"
                                size="medium"
                                color="warning"
                                onClick={handleOrderItemEdit}
                                sx={{mt: 3.5}}>
                    <EditRoundedIcon/> Edit
                </Button>);
            }

            return "";
        };

        if(order.combo){
            return (
                <Grid item xs={10} sm={10} md={10} padding={2}>
                    <ThemeProvider theme={lightTheme}>
                        <GridItem className="Order-item-grid-item">
                            <Grid container>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography variant="h5" component="div">
                                        {order.getLabel()}
                                    </Typography>
                                    <Typography>
                                        {order.getItemsLabel()}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography className="Order-item-price">{order.getAmount()} ISK</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{mt: 1}}>
                                <Grid item xs={12} sm={12} md={6} className="Order-item-left-btn">
                                    <RemoveOrderItem idx={idx}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className="Order-item-right-btn">
                                    <EditOrderItem/>
                                </Grid>
                            </Grid>
                        </GridItem>
                    </ThemeProvider>
                </Grid>
            );
        }

        return (
            <Grid item xs={10} sm={10} md={10} padding={2}>
                <ThemeProvider theme={lightTheme}>
                    <GridItem className="Order-item-grid-item">
                        <Grid container>
                            <Grid item xs={8} sm={8} md={8}>
                                <Typography variant="h5" component="div">
                                    {order.product.title}
                                </Typography>
                                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                    {order.getLabel()}
                                </Typography>
                                <Typography>
                                    {order.getToppingsLabel()}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <Typography className="Order-item-price">{order.amount} ISK</Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{mt: 1}}>
                            <Grid item xs={12} sm={12} md={6} className="Order-item-left-btn">
                                <RemoveOrderItem idx={idx}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className="Order-item-right-btn">
                                <EditOrderItem/>
                            </Grid>
                        </Grid>
                    </GridItem>
                </ThemeProvider>
            </Grid>
        );
    }
}