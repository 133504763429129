import {createSlice} from '@reduxjs/toolkit'
import {Combo} from "../models/Combo";
import {Product} from "../models/Product";

interface ComboCustomizationState {
    combo: Combo,
    current: number,
    items: []
}

const initialState: ComboCustomizationState = {
    combo: Combo,
    current: 0,
    items: []
};

export const comboCustomizationSlice = createSlice({
    name: 'comboCustomization',
    initialState: initialState,
    reducers: {
        setSelected: (state, {payload}) => {
            state.combo = {...payload.combo};
            state.current = 0;
            state.items = payload.combo.conditions.map((condition) => {
                const product = new Product({
                    title: `Pizza ${condition.size}`,
                    id: 1
                });
                product.setSizeTitle(payload.pizzaPrices, condition.size);
                return {
                    product: product,
                    size: condition.size,
                    count: 1,
                    selected: []
                }
            });
        },
        setCurrent: (state, {payload}) => {
            state.current = payload.current;
        },
        setSelectedComboFromCart: (state, {payload}) => {
            state.combo = {...payload.combo};
            state.items = [...payload.items];
            state.current = 0;
        },
        addComboTopping: (state, {payload}) => {
            const item = payload.topping;
            const idx = state.items[state.current].selected.findIndex(x => (x.id === item.id && x.type === item.type));
            if (idx !== -1) {
                let count = state.items[state.current].selected[idx].count;
                state.items[state.current].selected.splice(idx, 1);
                item.count = count + 1;
            }
            state.items[state.current].selected.push(item);
        },
        decrementComboTopping: (state, {payload}) => {
            const idx = state.items[state.current].selected.findIndex(x => (x.id === payload.id && x.type === payload.type));
            let count = state.items[state.current].selected[idx].count;
            if(count !== 0) {
                if (count === 1) {
                    state.items[state.current].selected.splice(idx, 1);
                } else {
                    const item = {...state.items[state.current].selected[idx]};
                    item.count = count - 1;
                    state.items[state.current].selected.splice(idx, 1);
                    state.items[state.current].selected.push(item);
                }
            }
        },
        removeComboTopping: (state, {payload}) => {
            const idx = state.items[state.current].selected.findIndex(x => (x.id === payload.id && x.type === payload.type));
            state.items[state.current].selected.splice(idx, 1);
        },
        setCountComboTopping: (state, {payload}) => {
            const idx = state.items[state.current].selected.findIndex(x => x.id === payload.id && x.type === payload.type);
            state.items[state.current].selected[idx].count = (state.items[state.current].selected[idx].count === 1) ? 2 : 1;
        },
        clear: () => initialState,
    },
})

// Action creators are generated for each case reducer function
export const {
    setSelected,
    setSelectedComboFromCart,
    addComboTopping,
    removeComboTopping,
    decrementComboTopping,
    setCountComboTopping,
    setCurrent,
    clear
} = comboCustomizationSlice.actions

export default comboCustomizationSlice.reducer