const paymentTypes = [
    {type: "card", label: "Credit Card"},
    {type: "cash", label: "Cash"},
];

const orderSource = {
    local: "local",
    phone: "phone"
};

const deliveryType = {
    inPlace: "0",
    takeAway: "1"
};

const defaultPaymentType = "card";

export {
    paymentTypes,
    defaultPaymentType,
    orderSource,
    deliveryType
};
