import * as React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import ToppingMenu from "../ToppingMenu";
import "./offer-modal.css";
import {useDispatch, useSelector} from "react-redux";
import {setOfferModal} from "../../store/modals-slice";
import {clear, setCurrent} from "../../store/combo-customization-slice";
import {addCartComboItem, editCartItem, updateCartComboItem} from "../../store/cart-slice";
import OfferModalSidebar from "../OfferModalSidebar";
import Maker from "../Maker";
import {useState} from "react";
import {PizzaTypes} from "../enum/pizza-types";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OfferModal = () => {
    const modalStatus = useSelector((state) => state.modals.offer);
    const data = useSelector((state) => state.comboCustomization);
    const currentCartItem = useSelector((state) => state.cart.currentItem);
    const dispatch = useDispatch();
    const pizzaPrices = useSelector((state) => state.config.prices);
    const [type, setType] = useState(PizzaTypes.Whole);

    if(data.items.length === 0) return null;

    const current = data.current;
    const isPrev = current !== 0;
    const isNext = data.items.length > 1 && current + 1 !== data.items.length;
    // const isCanSave = Object.values(data.items[current].selected).reduce((t, item) => t + item.count, 0) >= data.combo.conditions[current].toppings;
    const isCanSave = true;
    const Price = pizzaPrices.find((item) => item.size === parseInt(data.items[current].size));
    const size = Price.title;
    const allowHalfs = Price.allowHalfs;

    const handleClose = () => {
        setType(PizzaTypes.Whole);
        dispatch(clear());
        dispatch(editCartItem({idx: false, type: false}))
        if (modalStatus) {
            dispatch(setOfferModal());
        }
    };
    const handlePrev = () => {
        setType(PizzaTypes.Whole);
        dispatch(setCurrent({current: data.current - 1}));
    };
    const handleNext = () => {
        setType(PizzaTypes.Whole);
        dispatch(setCurrent({current: data.current + 1}));
    };
    const handleSave = () => {
        setType(PizzaTypes.Whole);
        if (currentCartItem !== false) {
            dispatch(updateCartComboItem({combo: data.combo, items: data.items, pizzaPrices}));
        } else {
            dispatch(addCartComboItem({combo: data.combo, items: data.items, pizzaPrices}));
        }
        dispatch(editCartItem({idx: false, type: false}))
        if (modalStatus) {
            dispatch(setOfferModal());
        }
    };

    return (
        <Dialog
            className="Offer-modal"
            open={modalStatus}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>CHOOSE TOPPINGS - OFFER</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} xs={8}>
                        {!!allowHalfs && <Maker type={type} setType={setType}/>}
                        <ToppingMenu type={type}/>
                    </Grid>
                    <Grid item lg={4} md={4} xs={4}>
                        <OfferModalSidebar type={type} data={data} dispatch={dispatch} size={size} allowHalfs={allowHalfs}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} variant="outlined" onClick={handleClose} sx={{mr: 5}}>Cancel</Button>
                {!!isPrev && <Button color={"primary"} variant="contained" onClick={handlePrev} sx={{mr: 5}}>Prev</Button>}
                {isNext && <Button color={"primary"} disabled={!isCanSave} variant="contained" onClick={handleNext}>Next</Button>}
                {!isNext && <Button color={"primary"} disabled={!isCanSave} variant="contained" onClick={handleSave}>Add to order</Button>}
            </DialogActions>
        </Dialog>
    );
};

export default OfferModal;