import * as React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PointOfSaleRoundedIcon from "@mui/icons-material/PointOfSaleRounded";
import Button from "@mui/material/Button";
import PhoneIcon from '@mui/icons-material/Phone';
import {totalPrice} from "../../helpers/order";

const OrderTotal = ({cart, handleOrderOpen, handlePhoneOrderOpen}) => {
    if (cart.items.length === 0) {
        return "";
    } else {
        return (
            <Grid container sx={{ml: 2, mt: 4}}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography>
                        {cart.items.length} positions
                    </Typography>
                    <Typography variant="h5" component="h4">
                        {totalPrice(cart)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{mt: 2}}>
                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} sx={{mt: 2}}>
                            <Button variant="contained"
                                    aria-label="add"
                                    size="large"
                                    className="Order-btn-order" onClick={handleOrderOpen}>
                                <PointOfSaleRoundedIcon sx={{mr: 1}}/>
                                Complete order
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{mt: 2}}>
                            <Button variant="contained"
                                    aria-label="add"
                                    size="large"
                                    className="Order-btn-phone" onClick={handlePhoneOrderOpen}>
                                <PhoneIcon sx={{mr: 1}}/>
                                Phone order
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default OrderTotal;