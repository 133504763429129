import * as React from 'react';
import Grid from "@mui/material/Grid";
import ToppingItem from "../ToppingItem";
import {useGetToppingsQuery} from "../../store/topping-api";
import {useDispatch, useSelector} from "react-redux";

const ToppingItems = ({id, type}) => {
    const menuCustom = useSelector((state) => state.menuCustomization);
    const menuSelected = menuCustom.selected.filter((item) => item.type === type);
    const comboState = useSelector((state) => state.comboCustomization);
    const modalOfferStatus = useSelector((state) => state.modals.offer);
    const dispatch = useDispatch();
    const {data = [], isLoading, isError} = useGetToppingsQuery();

    const Items = () => {
        if (data.length !== 0) {
            return data.map((item, idx) => item)
                .filter((item) => item.categoryId === id)
                .map((item, idx) => (
                    <ToppingItem
                        topping={item}
                        key={idx}
                        type={type}
                        selected={modalOfferStatus ? comboState.items[comboState.current].selected.filter((item) => item.type === type) : menuSelected}
                        dispatch={dispatch}
                    />
                ))
        } else {
            return (<div>No items</div>)
        }
    };

    if(isLoading) return (<div>Loading...</div>);
    if(isError) return (<div>Error...</div>);

    return (
        <Grid container>
            <Items/>
        </Grid>
    );
}

export default ToppingItems;