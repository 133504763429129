import {combineReducers, configureStore} from '@reduxjs/toolkit'
import menuCustomizationReducer from "./product-customization-slice";
import comboCustomizationReducer from "./combo-customization-slice";
import modalsReducer from "./modals-slice";
import cartReducer from "./cart-slice";
import payReducer from "./pay-slice";
import authReducer from "./auth-slice";
import {productApi} from "./product-api";
import {setupListeners} from "@reduxjs/toolkit/query";
import {toppingApi} from "./topping-api";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {jwt} from "../middlewares/jwt";
import configReducer from "./config-slice";
import {orderApi} from "./order-api";
import {combosApi} from "./combos-api";
import listenerMenuMiddleware from "../middlewares/listenerMenuMiddleware";

const rootReducer = combineReducers({
    comboCustomization: comboCustomizationReducer,
    menuCustomization: menuCustomizationReducer,
    modals: modalsReducer,
    cart: cartReducer,
    pay: payReducer,
    auth: authReducer,
    config: configReducer,
    [combosApi.reducerPath]: combosApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [toppingApi.reducerPath]: toppingApi.reducer
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['cart', 'auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            jwt,
            productApi.middleware,
            combosApi.middleware,
            orderApi.middleware,
            toppingApi.middleware
        ).prepend(listenerMenuMiddleware.middleware),
})
export const persistor = persistStore(store);

setupListeners(store.dispatch)