import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import theme from './theme';
import {persistor, store} from './store'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'

import App from './components/App/';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
            <Provider store={store}>
                <CssBaseline/>
                <App/>
            </Provider>
        </PersistGate>
    </ThemeProvider>,
    document.getElementById("root")
);
