import * as React from 'react';
import {Box, FormControl, InputAdornment, InputLabel, OutlinedInput, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {paymentTypes} from "../../data";
import Button from "@mui/material/Button";

const OrderCustomers = ({customersPaymentInfo, changeAmount, changePayment, handlePaid, focus}) => {
    return (
        customersPaymentInfo.map((item, idx) => (
            <Box sx={{mt: 2, ml: 2}} key={`box-${idx}`}>
                {!!changeAmount && <div>Guest {idx + 1}</div>}
                <FormControl sx={{m: 1, width: 140}} variant="outlined">
                    {!!changeAmount && <OutlinedInput
                        id={`outlined-adornment-weight-sum-${idx}`}
                        value={item.amount}
                        type={"number"}
                        autoFocus={focus === `amount-${idx}`}
                        onChange={changeAmount(idx)}
                        endAdornment={<InputAdornment position="end">ISK</InputAdornment>}
                        aria-describedby={`outlined-adornment-weight-sum-${idx}`}
                    />}
                    {!changeAmount && <OutlinedInput
                        id={`outlined-adornment-weight-sum-a-${idx}`}
                        value={item.amount}
                        type={"number"}
                        disabled={true}
                        endAdornment={<InputAdornment position="end">ISK</InputAdornment>}
                        aria-describedby={`outlined-adornment-weight-sum-a-${idx}`}
                    />}
                </FormControl>
                <FormControl key={`fc-${idx}`} sx={{mt: 1, ml: 1, minWidth: 220}}
                             variant="outlined">
                    <InputLabel key={`il-${idx}`}>Payment type</InputLabel>
                    <Select key={idx}
                            labelId={`select-payment-type-${idx}`}
                            id={`select-payment-type-${idx}`}
                            label="Cash"
                            onChange={changePayment(idx)}
                            value={item.payment}
                    >
                        {paymentTypes.map((item, idx) => {
                            return (
                                <MenuItem key={idx} value={item.type}>{item.label}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{m: 1, width: 100, height: 55}} key={`fc-btn-${idx}`}>
                    <Button
                        variant="contained"
                        aria-label="add"
                        disabled={!!item.paid}
                        color="primary"
                        size="large"
                        onClick={handlePaid(idx, item)}
                        sx={{height: 55}}
                        key={`btn-${idx}`}>
                        Paid
                    </Button>
                </FormControl>
            </Box>
        ))
    );
}

export default OrderCustomers;