import {HistoryOrderItemDto} from "../dto/HistoryOrderItemDto";
import {Customer} from "./Customer";

export class HistoryOrderItem {
    id: 0;
    amount: 0;
    date: "";
    time: "";
    paid: false;
    customer: null;
    receiptUrl: "";
    receiptPrintUrl: "";
    invoiceUrl: false;
    invoicePrintUrl: false;
    position: 0;

    constructor(base?: HistoryOrderItemDto) {
        if (base) {
            this.id = base.id;
            this.amount = base.amount;
            this.date = base.date;
            this.time = base.time;
            this.paid = base.paid;
            this.customer = base.customer ? new Customer(base.customer) : null;
            this.receiptUrl = base.receipt_url;
            this.receiptPrintUrl = base.receipt_print_url;
            this.invoiceUrl = base.invoice_url;
            this.invoicePrintUrl = base.invoice_print_url;
            this.position = base.position;
        }
    }
}
