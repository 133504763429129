import {refresh} from "../features/refresh";
import {logout, setRefresh} from "../store/auth-slice";

export function jwt({dispatch, getState}) {
    return (next) => (action) => {
        const auth = getState().auth;
        if (auth.accessToken) {
            // only worry about expiring token for async actions
            if (!auth.isRefreshing && action.meta && action.meta?.requestStatus) {
                const errors = [401, 403];
                if (action.meta?.requestStatus === "rejected" && action.meta?.condition === false
                    && errors.includes(action.payload.status)) {
                    dispatch(logout());

                    return dispatch(next(action));
                }
                // decode jwt so that we know if and when it expires
                const tokenExpiration = getState().auth.expiresIn;
                if ((tokenExpiration && (tokenExpiration - Math.round(new Date().getTime() / 1000)) < 60)) {
                    // make sure we are not already refreshing the token
                    dispatch(setRefresh());
                    dispatch(refresh(auth));

                    return dispatch(next(action));
                }
            }
        }
        return next(action);
    };
}