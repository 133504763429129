import Typography from "@mui/material/Typography";
import {Box, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import OrderCustomers from "../OrderCustomers";
import * as React from "react";
import {addCustomer, changeCustomerAmount, changeCustomerPaid, changeCustomerPayment, removeCustomer} from "../../store/cart-slice";
import {useDispatch} from "react-redux";
import {totalPrice} from "../../helpers/order";

const Receipt = ({handleChangeOpen, cart, focus, setFocus}) => {
    const dispatch = useDispatch();
    const upValue = () => dispatch(addCustomer());
    const downValue = () => dispatch(removeCustomer());
    const changeAmount = (idx) => (event) => {
        setFocus(`amount-${idx}`);
        dispatch(changeCustomerAmount({idx, amount: +event.target.value}))
    };
    const changePayment = (idx) => (event) => {
        dispatch(changeCustomerPayment({idx, payment: event.target.value}))
    };

    const handlePaid = (idx, item) => () => {
        if (item.payment === "card") {
            dispatch(changeCustomerPaid({idx, paid: true, paidAmount: item.amount, change: 0}));
        } else {
            handleChangeOpen(idx, item.amount)
        }
    };
    return (
        <div>
            <Typography variant="h6" component="h6" sx={{ml: 2}}>
                {totalPrice(cart)}
            </Typography>

            <Typography variant="h6" component="h6" sx={{ml: 2}}>
                Guests:
            </Typography>
            <Box sx={{ml: 2}}>
                <Button variant="contained" color={"inherit"} onClick={downValue}
                        sx={{padding: "7px"}}><RemoveIcon/></Button>
                <TextField size={"small"} disabled inputProps={{inputMode: 'numeric'}} value={cart.customers}
                           sx={{width: "60px", ml: 1, fontWeight: "bold"}}/>
                <Button variant="contained" color={"inherit"} onClick={upValue}
                        sx={{ml: 1, padding: "7px"}}><PlusOneIcon/></Button>
            </Box>
            <div className="Order-modal-customers-scroll">
                <OrderCustomers customersPaymentInfo={cart.customersPaymentInfo} changeAmount={changeAmount}
                             changePayment={changePayment} handlePaid={handlePaid} focus={focus}/>
            </div>
        </div>
    );
}

export default Receipt;