import * as React from 'react';
import "./topping-item.css";
import Grid from "@mui/material/Grid";
import {FormControlLabel, Paper, styled} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import {addTopping, decrementTopping, removeTopping} from "../../store/product-customization-slice";
import {useSelector} from "react-redux";
import {addComboTopping, decrementComboTopping, removeComboTopping} from "../../store/combo-customization-slice";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import PlusOneIcon from "@mui/icons-material/PlusOne";

const GridItem = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: '60px',
}));

const lightTheme = createTheme({palette: {mode: 'light'}});

const ToppingItem = ({topping, selected, dispatch, type}) => {
    const {id, title} = topping;
    const modalOfferStatus = useSelector((state) => state.modals.offer);
    const selectedItem = selected.find(item => item.id === id);
    const disabled = (selectedItem) ? !selectedItem.access : false;

    const plusHandle = () => {
        if (modalOfferStatus) {
            dispatch(addComboTopping({
                topping: {
                    ...topping,
                    type: type,
                    access: true
                }
            }));
        } else {
            dispatch(addTopping({
                topping: {
                    ...topping,
                    type: type,
                    access: true
                }
            }));
        }
    };

    const minusHandle = () => {
        if (selectedItem) {
            if (modalOfferStatus) {
                dispatch(decrementComboTopping({id, type}));
            } else {
                dispatch(decrementTopping({id, type}));
            }
        }
    };

    const CountItem = () => {
        if (selectedItem) {
            return (<div
                className="Topping-item-grid-item-count Topping-item-grid-item-color"> {selectedItem.count} </div>);
        }

        return (<div className="Topping-item-grid-item-count"> 0 </div>);
    };

    return (
        <Grid item xs={12} sm={6} md={4} padding={2} key={id}>
            <ThemeProvider theme={lightTheme}>
                <GridItem className="Topping-item-grid-item">
                    <Button variant="contained" color={"inherit"} disabled={disabled} onClick={minusHandle}
                            sx={{padding: "10px"}}><RemoveIcon/></Button>
                    <CountItem/>
                    <div className="Topping-item-grid-item-title"> {title} </div>
                    <Button variant="contained" color={"info"} disabled={disabled} onClick={plusHandle}
                            sx={{ml: 1, padding: "10px"}}><PlusOneIcon/></Button>
                </GridItem>
            </ThemeProvider>
        </Grid>
    );
}

export default ToppingItem;