import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {useDispatch} from "react-redux";
import {clearCart} from "../../store/cart-slice";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancelOrder() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch();

    const handleCloseAndAccept = () => {
        setOpen(false);
        dispatch(clearCart());
    };

    return (
        <div>
            <Button
                variant="outlined"
                aria-label="add"
                color="error"
                onClick={handleClickOpen}
                size="large"
                sx={{mt: 3.5, ml: 1, padding: "10px", fontSize: "11pt"}}>
                <CloseRoundedIcon sx={{mr: 1}}/>
                Cancel order
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Cancel order</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you confirm the cancellation of the order?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={"error"} variant="outlined" onClick={handleClose} sx={{mr: 5}}>Cancel</Button>
                    <Button color={"primary"} variant="contained" onClick={handleCloseAndAccept}>Agree</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}