import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {setSize} from "../store/product-customization-slice";
import {useSelector} from "react-redux";
import {defaultSize, setDefaultSize} from "../data";

export default function Sizes({state, dispatch}) {
    const PizzaSizes = useSelector((state) => state.config.prices);
    const ActivePizzaSizes = PizzaSizes.filter(x => x.isActive());

    const handleChange = (event, newAlignment) => {
        dispatch(setSize({size: newAlignment}))
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={state.size}
            exclusive
            onChange={handleChange}
        >
            {ActivePizzaSizes.map((item, idx) => (
                    <ToggleButton size={"large"} value={item.size} key={idx}>{item.title}</ToggleButton>
                )
            )}
        </ToggleButtonGroup>
    );
}