import {createListenerMiddleware, isAnyOf} from '@reduxjs/toolkit'
import {
    setSelected,
    setSelectedFromCart,
    addTopping,
    removeTopping,
    setCountTopping,
    setSize,
    setOffers
} from "../store/product-customization-slice";
import {PizzaTypes} from "../components/enum/pizza-types";

const listenerMenuMiddleware = createListenerMiddleware()

listenerMenuMiddleware.startListening({
    matcher: isAnyOf(setSelected, addTopping, removeTopping, setCountTopping, setSize, setSelectedFromCart),
    effect: async (action, listenerApi) => {
        const menu = listenerApi.getState().menuCustomization;
        let size = menu.size;
        if (listenerApi.getState().productApi.queries['getPrices(undefined)']) {
            const prices = listenerApi.getState().productApi.queries['getPrices(undefined)'].data;
            const ActivePizzaSizes = prices.filter(x => x.isActive());
            if (!ActivePizzaSizes.find(x => x.size === menu.size)) {
                size = ActivePizzaSizes[0].size;
                listenerApi.dispatch(setSize({size: ActivePizzaSizes[0].size}));
            }
        }
        if (listenerApi.getState().combosApi.queries['getSpecialCombos(undefined)']) {
            const data = listenerApi.getState().combosApi.queries['getSpecialCombos(undefined)'].data;
            const filtered = data.filter((item) => item.conditions.length === 1);

            const selected = menu.selected.reduce((partialSum, a) => partialSum + a.count * ((a.type === PizzaTypes.Whole) ? 1 : 0.5), 0);

            const offers = filtered.filter((item) =>
                item.conditions[0].size === size
                && selected >= item.conditions[0].toppings
            )

            listenerApi.dispatch(setOffers({offers}));
        }
    }
})

export default listenerMenuMiddleware;