import {Box, FormControl, InputAdornment, OutlinedInput, TextField} from "@mui/material";
import * as React from "react";
import {
    changeDiscountData
} from "../../store/cart-slice";
import {useDispatch} from "react-redux";

const Discount = ({cart, focus, setFocus}) => {
    const dispatch = useDispatch();
    const handleChangeData = (field) => (event) => {
        const payload = {};
        setFocus(`${field}-1`);
        let value = event.target.value;
        if (field === 'discount') {
            if (value < 0) {
                value = 0;
            }
            if (value > 100) {
                value = 100;
            }
        }
        payload[field] = value;
        dispatch(changeDiscountData({...payload}))
    };

    return (
        <div>
            <Box sx={{mt: 2, ml: 2, mb: 5}} key={`disc-1`}>
                <div>Discount</div>
                <FormControl sx={{mt: 1, width: 200}} variant="outlined">
                    <OutlinedInput
                        id={`outlined-adornment-weight-disc`}
                        aria-describedby="outlined-adornment-weight-disc"
                        value={cart.discount ? cart.discount : ""}
                        autoFocus={focus === `discount-1`}
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        onChange={handleChangeData('discount')}
                    />
                </FormControl>
                <FormControl sx={{mt: 1, ml: 1, width: 265}} variant="outlined">
                    <TextField
                        id={`outlined-adornment-weight-employer`}
                        aria-describedby="outlined-adornment-weight-employer"
                        value={cart.employer ? cart.employer : ""}
                        autoFocus={focus === `employer-1`}
                        required={!!cart.discount}
                        onChange={handleChangeData('employer')}
                        label="Employer code"
                    />
                </FormControl>
            </Box>
        </div>
    );
}

export default Discount;