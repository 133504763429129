import {createSlice} from '@reduxjs/toolkit';
import {login} from "../features/login";
import {refresh} from "../features/refresh";

const initialState = {
    accessToken: null,
    tokenType: null,
    expiresIn: null,
    isFetching: false,
    isSuccess: false,
    isRefreshing: false,
    isError: false,
    errorMessage: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        removeErrors: (state) => {
            state.isError = false;
        },
        setRefresh: (state) => {
            state.isRefreshing = true;
        },
        logout: () => initialState
    },
    extraReducers: {
        [login.fulfilled]: (state, { payload }) => {
            state.accessToken = payload.access_token;
            state.tokenType = payload.token_type;
            state.expiresIn = payload.expires_in;
            state.isFetching = false;
            state.isSuccess = true;
            state.isError = false;
        },
        [login.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload.error;
        },
        [login.pending]: (state) => {
            state.isFetching = true;
            state.isError = false;
        },
        [refresh.fulfilled]: (state, { payload }) => {
            state.accessToken = payload.access_token;
            state.tokenType = payload.token_type;
            state.expiresIn = payload.expires_in;
            state.isFetching = false;
            state.isRefreshing = false;
            state.isSuccess = true;
            state.isError = false;
        },
        [refresh.pending]: (state) => {
            state.isFetching = true;
            state.isRefreshing = true;
            state.isError = false;
        },
        [refresh.rejected]: () => initialState,
    }
});

export const {logout, removeErrors, setRefresh} = authSlice.actions;

export default authSlice.reducer;