import type {PriceDto} from "../dto/PriceDto";
import moment from "moment";

export class Price {
    id: number;
    size: number;
    title: string;
    price: number;
    inStockTime: number;
    allowHalfs: boolean;
    visible: boolean;

    constructor(base?: PriceDto) {
        if (base) {
            this.id = base.id;
            this.size = base.size;
            this.title = base.title;
            this.price = base.price;
            this.inStockTime = base.in_stock_time;
            this.allowHalfs = base.allow_halfs;
            this.visible = base.visible;
        }
    }

    isActive = () => {
        if (this.inStockTime.length > 0 && this.visible) {
            var today = moment().format('LL');
            const timeStart = moment(today + " " + this.inStockTime[0]).unix();
            const timeEnd = moment(today + " 15:00" + this.inStockTime[1]).unix();
            const cur = moment().unix();
            if (cur > timeStart && cur < timeEnd + 1) {
                return true;
            } else {
                return false;
            }
        }

        return this.visible;
    };
}
