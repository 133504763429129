import * as React from 'react';
import Grid from "@mui/material/Grid";
import Loader from "../Loader";
import OrderHistoryItem from "../OrderHistoryItem";
import {useGetOrdersQuery} from "../../store/order-api";
import Stack from "@mui/material/Stack";
import {Pagination} from "@mui/lab";
import {useState} from "react";
import {IconButton, InputBase, Paper} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {setPayModal} from "../../store/modals-slice";
import {useDispatch} from "react-redux";
import {setData} from "../../store/pay-slice";

const OrderHistoryItems = () => {
    const dispatch = useDispatch();
    let searchKeywords = "";
    const [currentPage, setPage] = useState(1);
    const [keywords, setKeywords] = useState(searchKeywords);
    let {data = [], currentData, isLoading, isFetching, isError} = useGetOrdersQuery({page: currentPage, keywords});

    const changePage = (event, page) => {
        setPage(page);
    }

    const handlePayOpen = (item) => {
        dispatch(setData(item))
        dispatch(setPayModal());
    };

    const Items = () => {
        if (isLoading || (isFetching && !currentData)) return (<Loader/>);
        if (isError) return (<div>Error...</div>);

        if(data.data.length === 0) return (<div>No data found</div>);

        return data.data.map((item, idx) => item)
            .map((item, idx) => (
                <OrderHistoryItem item={item} key={idx} handlePayOpen={handlePayOpen}/>
            ));
    };

    const handleSearch = (event) => {
        searchKeywords = event.target.value;
    };

    const handleSearchSubmit = () => {
        setPage(1);
        setKeywords(searchKeywords)
    };

    const CustomizedInputBase = () => {
        return (
            <Paper
                component="form"
                sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%"}}
            >
                <InputBase
                    sx={{ml: 1, flex: 1}}
                    placeholder="Search Order"
                    inputProps={{'aria-label': 'search google maps'}}
                    defaultValue={keywords}
                    onKeyUp={handleSearch}
                />
                <IconButton onClick={handleSearchSubmit} sx={{p: '10px'}} aria-label="search">
                    <SearchIcon/>
                </IconButton>
            </Paper>
        );
    }

    return (
        <div>
            <CustomizedInputBase/>
            <Grid container>
                <Items/>
            </Grid>
            <Stack spacing={2} sx={{display: "table", margin: "0 auto"}}>
                <Pagination count={data.pages} defaultPage={1} page={currentPage} onChange={changePage} size="large"/>
            </Stack>
        </div>
    );
}

export default OrderHistoryItems;