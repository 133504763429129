import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Navigate} from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";
import './index.css'
import {useAuth} from "../../hooks/use-auth";
import {login} from "../../features/login";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import CustomizedSnackbars from "../../components/Notification";
import {logout, removeErrors} from "../../store/auth-slice";

const SignIn = () => {
    const {isAuth, isFetching, isError, errorMessage} = useAuth();
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(login({
            username: "pos_user",
            password: password,
        }));
    };
    useEffect(() => {
        if (!isAuth && isFetching) {
            dispatch(logout());
        }
    }, [isAuth, isFetching]);
    if (isAuth) {
        return (<Navigate to="/dashboard"/>);
    }

    return (
        <div>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img
                    src="/images/logo.png"
                    loading="lazy"
                />
                <Typography component="h1" variant="h2">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                    <div className="custom-styles">
                        <ReactInputVerificationCode onChange={setPassword} type="password" length={4} passwordMask="*"
                                                    autoFocus={true}/>
                    </div>
                    {isFetching && <Loader/>}
                    {!isFetching && <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{mt: 10, mb: 2}}
                        className="btn-login"
                    >
                        Sign In
                    </Button>}
                </Box>
            </Box>
            <CustomizedSnackbars type="error" open={isError} dispath={removeErrors} message={errorMessage}/>
        </div>
    );
}

export default SignIn;