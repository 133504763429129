import {ToppingCategoryDto} from "../dto/ToppingCategoryDto";

export class ToppingCategory {
    id: number;
    title: string;
    icon: string;

    constructor(base?: ToppingCategoryDto) {
        if (base) {
            this.id = base.id;
            this.title = base.title;
            this.icon = base.icon;
        }
    }
}
