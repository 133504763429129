import { createApi } from '@reduxjs/toolkit/query/react'
import type {ToppingDto} from "../dto/ToppingDto";
import {Topping} from "../models/Topping";
import type {ToppingCategoryDto} from "../dto/ToppingCategoryDto";
import {ToppingCategory} from "../models/ToppingCategory";
import {staggeredBaseQueryWithBailOut} from "../helpers/api";

export const toppingApi = createApi({
    reducerPath: 'toppingApi',
    baseQuery: staggeredBaseQueryWithBailOut,
    endpoints: (builder) => ({
        getToppings: builder.query({
            query: () => `toppings`,
            transformResponse: (response: ToppingDto[], meta, arg) => {
                return response.map((item) => new Topping(item));
            }
        }),
        getToppingCategories: builder.query({
            query: () => `toppings/categories`,
            transformResponse: (response: ToppingCategoryDto[], meta, arg) => {
                return response.map((item) => new ToppingCategory(item));
            }
        }),
    }),
})

export const { useGetToppingsQuery, useGetToppingCategoriesQuery } = toppingApi;
