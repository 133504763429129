import { createApi } from '@reduxjs/toolkit/query/react'
import {staggeredBaseQueryWithBailOut} from "../helpers/api";
import type {OrderDto} from "../dto/OrderDto";
import {Order} from "../models/Order";

export const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery: staggeredBaseQueryWithBailOut,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: ({page, keywords}) => `orders?page=${page}&keywords=${keywords}`,
            transformResponse: (response: OrderDto[], meta, arg) => {
                return new Order(response);
            }
        }),
        printOrder: builder.mutation({
            query({url}) {
                return {
                    url: `${url}`,
                    method: 'GET'
                }
            },
        }),
        printInvoice: builder.mutation({
            query({url}) {
                return {
                    url: `${url}`,
                    method: 'GET'
                }
            },
        }),
        createOrder: builder.mutation({
            query(body) {
                return {
                    url: `orders`,
                    method: 'POST',
                    body: body,
                }
            },
        }),
        updateOrder: builder.mutation({
            query({id, body}) {
                return {
                    url: `orders/${id}`,
                    method: 'PUT',
                    body: body,
                }
            },
        }),
    }),
})

export const { useCreateOrderMutation, useGetOrdersQuery, useUpdateOrderMutation, usePrintOrderMutation, usePrintInvoiceMutation } = orderApi;
