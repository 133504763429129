import {ProductCategoryDto} from "../dto/ProductCategoryDto";

export class ProductCategory {
    id: number;
    title: string;
    icon: string;
    customize: boolean;

    constructor(base?: ProductCategoryDto) {
        if (base) {
            this.id = base.id;
            this.title = base.title;
            this.icon = base.icon;
            this.customize = base.customize;
        }
    }
}
