import React, {useState} from 'react';
import {Tab, Box} from '@mui/material';
import {TabPanel, TabContext, TabList} from '@mui/lab';
import {tabsClasses} from '@mui/material/Tabs';
import MenuItems from "../MenuItems";
import Svg from "../Svg";
import "./menu.css";
import ToppingModal from "../ToppingModal";
import {useGetPricesQuery, useGetProductCategoriesQuery} from "../../store/product-api";
import Loader from "../Loader";
import OfferItems from "../OfferItems";
import OfferModal from "../OfferModal";
import OrderHistoryItems from "../OrderHistoryItems";
import {useGetSpecialCombosQuery} from "../../store/combos-api";

const Menu = () => {
    const [value, setValue] = useState("1");
    useGetPricesQuery();
    const {data = [], isLoading, isError} = useGetProductCategoriesQuery();
    const getCombosQuery = useGetSpecialCombosQuery();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isLoading) return (<Loader/>);
    if (isError) return (<div>Error...</div>);

    return (
        <div>
            <ToppingModal/>
            <OfferModal/>
            <TabContext value={value.toString()}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList
                        allowScrollButtonsMobile
                        scrollButtons
                        variant="scrollable"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': {opacity: 0.3},
                            },
                        }}
                        onChange={handleChange} aria-label="Devitos menu items">
                        <Tab value={"0"} key={"tab-0"} icon={<Svg icon={"fa-bookmark"}/>} label={"Offers"}/>
                        {data.map(({title, icon, id}, idx) => (
                                <Tab value={(idx+1).toString()} key={idx} icon={<Svg icon={icon}/>} label={title}/>
                            )
                        )}
                        <Tab value={"orders"} key={"tab-orders"} icon={<Svg icon={"fa-folder-open"}/>} label={"Orders"}/>
                    </TabList>
                </Box>
                <div className="Menu-scroll">
                    <TabPanel key={"tab-p-0"} value={"0"}>
                        <OfferItems key={"tab-p-items=0"} getCombosQuery={getCombosQuery}/>
                    </TabPanel>
                    {data.map(({title, id, customize}, idx) => (
                            <TabPanel key={(idx+1)} value={(idx+1).toString()}>
                                <MenuItems key={(idx+1)} type={id} customize={customize}/>
                            </TabPanel>
                        )
                    )}
                    <TabPanel key={"tab-p-orders"} value={"orders"}>
                        <OrderHistoryItems key={"tab-p-items=orders"}/>
                    </TabPanel>
                </div>
            </TabContext>
        </div>
    );
}

export default Menu;