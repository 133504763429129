import * as React from 'react';
import Grid from "@mui/material/Grid";
import Loader from "../Loader";
import OfferItem from "../OfferItem";

const OfferItems = ({getCombosQuery}) => {
    const {data = [], isLoading, isError} = getCombosQuery

    const Items = () => {
        if (isLoading) return (<Loader/>);
        if (isError) return (<div>Error...</div>);

        return data.filter(x => x.isActive()).map((item, idx) => item)
            .map((item, idx) => (
                <OfferItem combo={item} key={idx}/>
            ));
    };
    return (
        <Grid container>
            <Items/>
        </Grid>
    );
}

export default OfferItems;