import {defaultPaymentType, deliveryType, orderSource} from "../data";
import {combinedPizzaTypes, PizzaTypes} from "../components/enum/pizza-types";

export const payPrepareToSend = (cart) => {
    return {
        amount: cart.totalPrice,
        discount: cart.discount ? +cart.discount : null,
        discount_price: cart.discount ? +cart.discountPrice : 0,
        employer: cart.discount ? cart.employer : null,
        customers: cart.customers,
        invoice: cart.invoice,
        delivery_type: cart.deliveryType ? deliveryType.takeAway : deliveryType.inPlace,
        customers_payment_info: JSON.stringify(cart.customersPaymentInfo)
    };
};

export const cartPrepareToSend = (cart, prices, source) => {
    return {
        amount: cart.totalPrice,
        discount: cart.discount ? +cart.discount : null,
        discount_price: cart.discount ? +cart.discountPrice : 0,
        employer: cart.discount ? cart.employer : null,
        customers: cart.customers,
        invoice: cart.invoice,
        delivery_type: cart.deliveryType ? deliveryType.takeAway : deliveryType.inPlace,
        source: source,
        customers_payment_info: JSON.stringify((source === orderSource.local) ? cart.customersPaymentInfo : [cart.customersPaymentInfo[0]]),
        items: JSON.stringify(cart.items.map((item) => {
            if (item.combo) {
                return {
                    object: "combo",
                    id: item.combo.id,
                    amount: item.combo.price,
                    items: item.items.map((c) => {
                        return {
                            id: c.product ? c.product.id : 1,
                            object: "product",
                            quantity: c.count,
                            size: c.size ? getSizeId(prices, c.size) : null,
                            type: c.type,
                            amount: item.getAmount(),
                            toppings: c.selected.map((top) => {
                                return {
                                    id: top.id,
                                    quantity: top.count,
                                    type: top.type
                                }
                            })
                        }
                    })
                }
            }
            return {
                id: item.id ? item.id : 1,
                object: "product",
                quantity: item.count,
                size: item.size ? getSizeId(prices, item.size) : null,
                type: item.type,
                amount: item.amount,
                toppings: prepareToppings(item.toppings, item.product)
            }
        }))
    };
};

const prepareToppings = (toppings, product) => {
    const arr = [];
    for (const topping of toppings) {
        const top = product.toppings.find(x => x.id === topping.id && topping.type === PizzaTypes.Whole);
        if (top) {
            const c = topping.count - top.count;
            if (c > 0) {
                arr.push({
                    id: topping.id,
                    quantity: c,
                    type: topping.type
                });
            }
        } else {
            arr.push({
                id: topping.id,
                quantity: topping.count,
                type: topping.type
            });
        }
    }

    return arr;
};

export const getSizeId = (prices, size) => {
    return prices.filter(obj => {
        return obj.size === parseInt(size)
    }).map(x => x.id)[0]
}

export const getCustomersTotalSum = (items) => {
    return Object.values(items).reduce((t, item) => t + item.amount, 0);
}

export const getTotalSum = (items) => {
    return Object.values(items).reduce((t, item) => t + (item.combo ? getSum(item) : (item.count * (getSum(item) + (item.product ? item.product.price : 0)))), 0);
}

export const discountPrice = (state) => {
    let discountPrice = 0;
    if (state.discount) {
        discountPrice = parseFloat(state.sum * (state.discount / 100));
    }

    return discountPrice;
}

export const getSum = (item) => {
    if (item.combo) return item.getAmount();

    return (item.toppings && item.toppings.length !== 0) ?
        Object.values(item.toppings).reduce((t, {
            prices,
            count,
            type
        }) => {
            let sum = 0;
            let price = prices[`size${item.size}`];
            if(combinedPizzaTypes.half_a.includes(type)) {
                price /= 2;
            }
            sum = sum + (count * price);
            return t + sum
        }, 0) : 0;
}

export const arrCustomers = (customers, total) => {
    const arr = [];
    let perCustomer = Math.floor(total / customers);
    let sum = total;
    for (let i = 0; i < customers; i++) {
        if (i + 1 !== customers) {
            sum -= perCustomer;
        } else {
            perCustomer = sum;
        }
        arr.push({
            amount: perCustomer,
            payment: defaultPaymentType,
            paid: false,
            paidAmount: 0,
            change: 0,
            ssn: "",
            companyName: "",
            name: "",
            phone: "",
        });
    }
    return arr;
}

export const totalPrice = (cart) => {
    return `Total - ${cart.totalPrice ? cart.totalPrice : cart.sum} ISK`;
}

