import {ProductDto} from "../dto/ProductDto";
import {Topping} from "./Topping";
import {Price} from "./Price";

export class Product {
    id: number;
    title: string;
    description: string;
    categoryId: number;
    price: ?number;
    size: ?string;
    toppings: Topping[] = [];
    selected: Topping[] = [];

    constructor(base?: ProductDto) {
        if (base) {
            this.id = base.id ? base.id : 1;
            this.title = base.title;
            this.description = base.description;
            this.categoryId = base.category_id;
            this.price = base.price;
            this.toppings = base.toppings
                ? base.toppings.map((data) => new Topping(data))
                : [];
            this.selected = base.selected
                ? base.selected.map((data) => new Topping(data))
                : [];
        }
    }

    setPrice = (prices: Price[], size: number) => {
        this.price = prices ? prices.filter(obj => {
            return obj.size === size
        }).map(x => x.price)[0] : this.price;
    };

    setSizeTitle = (prices: Price[], size: number) => {
        if (!prices && this.price) {
            return this.price;
        }
        this.size = prices.find((item) => item.size === size).title;
    };
}
