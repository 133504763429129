import type {OrderItemDto} from "../dto/OrderItemDto";
import {Topping} from "./Topping";
import {Product} from "./Product";
import {PizzaTypes} from "../components/enum/pizza-types";
import {getSum} from "../helpers/order";

export class OrderItem {
    id: number;
    toppings: Topping[] = [];
    count: number;
    size: ?number;
    product: Product
    type: ?PizzaTypes.Whole;
    amount: ?number;

    constructor(base?: OrderItemDto) {
        if (base) {
            this.id = base.id;
            this.toppings = base.toppings
                ? base.toppings.map((data) => new Topping(data))
                : [];
            this.count = base.count ? base.count : 1;
            this.size = base.size ? base.size : null;
            this.product = base.product;
            this.type = base.type ? base.type : false;
            this.amount = this.getSum();
        }
    }

    getSum = () => {
        let toppingSum = Math.trunc(getSum(this));
        const price = Math.trunc(this.product.price);
        return this.count * (toppingSum + price);
    };

    getLabel = () => {
        const size = ((this.size) ? `${this.product.size} pizza.` : "");

        return `${this.count} stk ${size}`;
    };

    getToppingsLabel = () => {
        const WholeItems = this.toppings.filter((item) => item.type === PizzaTypes.Whole).map((item) => (item.count === 1) ? item.title : `${item.count}x ${item.title}`).join(", ");
        const halfAItems = this.toppings.filter((item) => item.type === PizzaTypes.HalfA).map((item) => (item.count === 1) ? item.title : `${item.count}x ${item.title}`).join(", ");
        const halfBItems = this.toppings.filter((item) => item.type === PizzaTypes.HalfB).map((item) => (item.count === 1) ? item.title : `${item.count}x ${item.title}`).join(", ");

        const wholeLabel = (WholeItems.length > 0) ? `Whole: ${WholeItems}` : '';
        const halfALabel = (halfAItems.length > 0) ? `First half: ${halfAItems}` : '';
        const halfBLabel = (halfBItems.length > 0) ? `Second half: ${halfBItems}` : '';

        return `${wholeLabel} \n ${halfALabel} \n ${halfBLabel}`;
    };
}
