import * as React from 'react';
import "./order.css";
import OrderItem from "../OrderItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import OrderButtons from "../OrderButtons";
import OrderTotal from "./order-total";
import {useDispatch, useSelector} from "react-redux";
import {
    setOrderModal,
    setPhoneOrderModal,
} from "../../store/modals-slice";

const Order = () => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const handleOrderOpen = () => dispatch(setOrderModal());
    const handlePhoneOrderOpen = () => dispatch(setPhoneOrderModal());

    const CartItems = () => {
        if (cart.items.length !== 0) {
            return cart.items.map((item, idx) => (
                <OrderItem key={idx} order={item} idx={idx} dispatch={dispatch} cart={cart}/>
            ))
        } else {
            return (<Grid container sx={{ml: 2}}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography sx={{mb: 1.5, mt: 2}} color="text.secondary">
                        Cart is empty!
                    </Typography>
                </Grid>
            </Grid>);
        }
    };

    return (
        <div>
            <OrderButtons/>
            <Typography variant="h4" component="h4" sx={{mt: 8, ml: 2}}>
                Order
            </Typography>
            <div className="Order-scroll">
                <Grid container>
                    <CartItems/>
                </Grid>
            </div>
            <OrderTotal cart={cart} handleOrderOpen={handleOrderOpen} handlePhoneOrderOpen={handlePhoneOrderOpen}/>
        </div>
    );
}

export default Order;