import * as React from 'react';
import "./offer-item.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Paper, styled} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {setSelected} from "../../store/combo-customization-slice";
import {setOfferModal} from "../../store/modals-slice";

const GridItem = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: '60px',
}));

const lightTheme = createTheme({palette: {mode: 'light'}});

const OfferItem = ({combo}) => {
    const dispatch = useDispatch();
    const {id, title, description, price} = combo;
    const pizzaPrices = useSelector((state) => state.config.prices);

    const handleOpen = () => {
        dispatch(setSelected({combo, pizzaPrices}));
        dispatch(setOfferModal());
    }
    return (
        <Grid item xs={12} sm={6} md={4} padding={2} key={id}>
            <ThemeProvider theme={lightTheme}>
                <GridItem className="Menu-item-grid-item">
                    <Typography key={id} variant="h6" component="h6" className="Menu-item-title">
                        {title}
                    </Typography>
                    <Typography sx={{mb: 1.5, mt: 2}} className="Menu-item-description" color="text.secondary">
                        {description}
                    </Typography>
                    {price && <Typography sx={{mb: 1, mt: 1}} className="Menu-item-price" color="text.secondary">
                        {price} ISK
                    </Typography>}
                    <Button type="submit"
                            className="Menu-item-button-menu-item"
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{mt: 1, mb: 2}}
                            onClick={handleOpen}
                    > ORDER </Button>
                </GridItem>
            </ThemeProvider>
        </Grid>
    );
}

export default OfferItem;