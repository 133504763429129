import * as React from 'react';
import Grid from "@mui/material/Grid";
import LocalPizzaRoundedIcon from "@mui/icons-material/LocalPizzaRounded";
import './order-buttons.css';
import CancelOrder from "../Dialogs/confirm";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {setMakerModal} from "../../store/modals-slice";
import {setSelected} from "../../store/product-customization-slice";
import {Product} from "../../models/Product";
import {editCartItem} from "../../store/cart-slice";
import {PizzaTypes} from "../enum/pizza-types";

export default function OrderButtons() {
    const dispatch = useDispatch();
    const handleOpen = () => {
        dispatch(setSelected({
            product: new Product({
                title: "Custom pizza",
                id: 1
            })
        }))
        dispatch(editCartItem({idx: false, type: PizzaTypes.Whole}))
        dispatch(setMakerModal())
    };
    return (
        <Grid container>
            <Grid item lg={6} md={6} xs={12} sm={12}>
                <Button
                    variant="extended"
                    aria-label="add"
                    className="Order-btn-creator"
                    onClick={handleOpen}
                    size="large"
                    sx={{mt: 3.5}}>
                    <LocalPizzaRoundedIcon sx={{mr: 1}}/>
                    Make Pizza
                </Button>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
                <CancelOrder/>
            </Grid>
        </Grid>
    );
}