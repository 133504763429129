import { createApi } from '@reduxjs/toolkit/query/react'
import type {ProductDto} from "../dto/ProductDto";
import {Product} from "../models/Product";
import {Price} from "../models/Price";
import type {ProductCategoryDto} from "../dto/ProductCategoryDto";
import {ProductCategory} from "../models/ProductCategory";
import {staggeredBaseQueryWithBailOut} from "../helpers/api";
import type {PriceDto} from "../dto/PriceDto";

export const productApi = createApi({
    reducerPath: 'productApi',
    baseQuery: staggeredBaseQueryWithBailOut,
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: () => `products`,
            transformResponse: (response: ProductDto[], meta, arg) => {
                return response.map((item) => new Product(item));
            }
        }),
        getPrices: builder.query({
            query: () => `products/prices`,
            transformResponse: (response: PriceDto[], meta, arg) => {
                return response.map((item) => new Price(item));
            }
        }),
        getProductCategories: builder.query({
            query: () => `products/categories`,
            transformResponse: (response: ProductCategoryDto[], meta, arg) => {
                return response.map((item) => new ProductCategory(item));
            }
        }),
    }),
})

export const { useGetProductsQuery, useGetProductCategoriesQuery, useGetPricesQuery } = productApi;
