import Typography from "@mui/material/Typography";
import {Box, FormControl, TextField} from "@mui/material";
import OrderCustomers from "../OrderCustomers";
import * as React from "react";
import {
    changeCustomerCompanyName,
    changeCustomerPaid,
    changeCustomerPayment,
    changeCustomerSsn
} from "../../store/cart-slice";
import {useDispatch} from "react-redux";
import {totalPrice} from "../../helpers/order";

const Invoice = ({handleChangeOpen, cart, focus, setFocus}) => {
    const dispatch = useDispatch();
    const changePayment = (idx) => (event) => {
        dispatch(changeCustomerPayment({idx, payment: event.target.value}))
    };

    const handlePaid = (idx, item) => () => {
        if (item.payment === "card") {
            dispatch(changeCustomerPaid({idx, paid: true, paidAmount: item.amount, change: 0}));
        } else {
            handleChangeOpen(idx, item.amount)
        }
    };
    const handleChangeSsn = (idx) => (event) => {
        setFocus(`ssn-${idx}`);
        const regex = /^[0-9]{0,10}$/g;
        let str = event.target.value;
        if(str.match(regex)) {
            str = str.match(regex).filter(function (m, i, self) {
                return i === self.indexOf(m)
            }).join('')
            dispatch(changeCustomerSsn({idx, ssn: str}))
        }
    };

    const handleChangeCompanyName = (idx) => (event) => {
        setFocus(`name-${idx}`);
        dispatch(changeCustomerCompanyName({idx, companyName: event.target.value}))
    };

    return (
        <div>
            <Typography variant="h6" component="h6" sx={{ml: 2}}>
                {totalPrice(cart)}
            </Typography>

            {cart.customersPaymentInfo.map((item, idx) => (
            <Box sx={{mt: 2, ml: 2}} key={`inv-${idx}`}>
                <FormControl sx={{m: 1, width: 200}} variant="outlined">
                    <TextField
                        id={`outlined-adornment-weight-vat`}
                        aria-describedby="outlined-adornment-weight-vat"
                        value={item.ssn}
                        autoFocus={focus === `ssn-${idx}`}
                        onChange={handleChangeSsn(idx)}
                        label="SSN/Kennitala"
                        required={true}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                </FormControl>
                <FormControl sx={{m: 1, width: 265}} variant="outlined">
                    <TextField
                        id={`outlined-adornment-weight-name`}
                        aria-describedby="outlined-adornment-weight-name"
                        value={item.companyName}
                        autoFocus={focus === `name-${idx}`}
                        onChange={handleChangeCompanyName(idx)}
                        required={true}
                        label="Company name"
                    />
                </FormControl>
            </Box>))}

            <div className="Order-modal-customers-scroll">
                <OrderCustomers customersPaymentInfo={cart.customersPaymentInfo} changeAmount={null}
                             changePayment={changePayment} handlePaid={handlePaid}/>
            </div>
        </div>
    );
}

export default Invoice;