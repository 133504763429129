import * as React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import "./pay-modal.css";
import Typography from "@mui/material/Typography";
import {} from "../../helpers/math"
import Receipt from "./receipt"
import {useDispatch, useSelector} from "react-redux";
import {getCustomersTotalSum, payPrepareToSend} from "../../helpers/order";
import InvoiceSwitch from "../Switch";
import Invoice from "./invoice";
import {useUpdateOrderMutation} from "../../store/order-api";
import {changeCustomerType, clearCart} from "../../store/pay-slice";
import {useEffect, useState} from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PayModal = ({handleClose, handlePrintOpen, handleChangeOpen}) => {
    const dispatch = useDispatch();
    const [focus, setFocus] = useState(null);
    const modalStatus = useSelector((state) => state.modals.pay);
    const cart = useSelector((state) => state.pay);
    const [updateOrder, {data, isLoading, isSuccess, isError}] = useUpdateOrderMutation();
    const handleOrder = () => {
        updateOrder({id: cart.id, body: payPrepareToSend(cart)}).unwrap();
    };
    useEffect(() => {
        if (isSuccess) {
            dispatch(clearCart());
            handleClose();
            if (data.receipt_url)
                handlePrintOpen(data, null)
        }
    }, [isSuccess])

    const handleChangeCustomerType = () => {
        dispatch(changeCustomerType());
    };

    const customersTotalSum = getCustomersTotalSum(cart.customersPaymentInfo);
    const sumDiff = cart.totalPrice - customersTotalSum;
    const paidStatus = cart.customersPaymentInfo.reduce((sum, next) => sum && next.paid, true);

    const OrderModalContent = () => {
        return (<div>
            <Typography variant="h6" component="h6" sx={{ml: 2, mb: 5}}>
                <InvoiceSwitch status={cart.invoice} handleChangeCustomerType={handleChangeCustomerType}/>
            </Typography>
            {!cart.invoice && <Receipt handleChangeOpen={handleChangeOpen} cart={cart} focus={focus} setFocus={setFocus}/>}
            {!!cart.invoice && <Invoice handleChangeOpen={handleChangeOpen} cart={cart} focus={focus} setFocus={setFocus}/>}

            {
                !!sumDiff && <Typography variant="h6" component="h6" sx={{ml: 2, color: "red"}}>
                    Error - There is a lack of {sumDiff} ISK to the whole amount
                </Typography>
            }
            {
                !!isError && <Typography variant="h6" component="h6" sx={{ml: 2, color: "red"}}>
                    Error
                </Typography>
            }
        </div>);
    }

    return (
        <Dialog className="Pay-modal"
                open={modalStatus}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Order pay</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xs={6}>
                        <Typography variant="h6" component="h6" sx={{ml: 2}}>
                            Order
                        </Typography>
                        <div className="Pay-modal-order-scroll">
                            <Grid item xs={12} md={12}>
                                <iframe id="receipt" src={cart.receiptUrl} width={430} height={500}/>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={6}>
                        <OrderModalContent/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} variant="outlined" onClick={handleClose} sx={{mr: 5}}>Cancel</Button>
                <Button
                    disabled={!!isLoading || !!sumDiff || !paidStatus}
                    color={"primary"} variant="contained"
                    onClick={handleOrder}>Pay confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PayModal;