import {createSlice} from '@reduxjs/toolkit'

interface ModalState {
    order: boolean,
    phoneOrder: boolean,
    pay: boolean,
    menu: boolean,
    maker: boolean,
    offer: boolean,
    print: boolean,
    change: boolean,
    modalUrl: ?string,
    modalPrintUrl: ?string,
    modalInvoiceUrl: ?string,
    modalOrderNumber: ?number,
    orderNumber: boolean
}

const initialState: ModalState = {
    order: false,
    phoneOrder: false,
    pay: false,
    menu: false,
    maker: false,
    offer: false,
    print: false,
    change: false,
    modalUrl: null,
    modalPrintUrl: null,
    modalInvoiceUrl: null,
    modalOrderNumber: null,
    orderNumber: false
};

export const modalsSlice = createSlice({
    name: 'modals',
    initialState: initialState,
    reducers: {
        setOrderModal: (state) => {
            state.order = !state.order;
        },
        setPayModal: (state) => {
            state.pay = !state.pay;
        },
        setOrderNumberModal: (state, {payload}) => {
            state.orderNumber = !state.orderNumber;
            state.modalOrderNumber = payload?.id ? payload.id : null;
        },
        setPhoneOrderModal: (state) => {
            state.phoneOrder = !state.phoneOrder;
        },
        setMenuModal: (state) => {
            state.menu = !state.menu;
        },
        setMakerModal: (state) => {
            state.maker = !state.maker;
        },
        setOfferModal: (state) => {
            state.offer = !state.offer;
        },
        setPrintModal: (state, {payload}) => {
            state.print = !state.print;
            state.modalUrl = payload?.url ? payload.url : null;
            state.modalPrintUrl = payload?.printUrl ? payload.printUrl : null;
            state.modalInvoiceUrl = payload?.invoiceUrl ? payload.invoiceUrl : null;
        },
        setChangeModal: (state) => {
            state.change = !state.change;
        },
        closeModals: (state) => {
            state.order = initialState.order;
            state.phoneOrder = initialState.phoneOrder;
            state.menu = initialState.menu;
            state.maker = initialState.maker;
            state.offer = initialState.offer;
            state.print = initialState.print;
            state.change = initialState.change;
            state.pay = initialState.pay;
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setOrderModal,
    setPayModal,
    setPhoneOrderModal,
    setMenuModal,
    setMakerModal,
    setOfferModal,
    setPrintModal,
    setChangeModal,
    closeModals,
    setOrderNumberModal
} = modalsSlice.actions

export default modalsSlice.reducer