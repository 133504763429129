import {createAsyncThunk} from "@reduxjs/toolkit";


export const refresh = createAsyncThunk(
    "auth/refresh",
    async ({ accessToken, tokenType }, thunkAPI) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/refresh`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                    },
                }
            )
            let data = await response.json();
            if (response.status === 200) {
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (e) {
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
)