import {createSlice} from '@reduxjs/toolkit'
import {OrderItem} from "../models/OrderItem";
import {combinedPizzaTypes} from "../components/enum/pizza-types";
import {Product} from "../models/Product";
import {arrCustomers, discountPrice, getTotalSum} from "../helpers/order";
import {defaultPaymentType} from "../data";
import {OrderComboItem} from "../models/OrderComboItem";

interface CartState {
    items: OrderItem[],
    discountPrice: number,
    discount: ?number,
    employer: ?number,
    sum: number,
    totalPrice: number,
    currentItem: ?number,
    currentTypeItem: ?string,
    invoice: boolean,
    customers: number,
    deliveryType: boolean,
    customersPaymentInfo: []
}

const initialState: CartState = {
    items: [],
    sum: 0,
    totalPrice: 0,
    discountPrice: 0,
    discount: null,
    employer: null,
    currentItem: false,
    currentTypeItem: false,
    invoice: false,
    customers: 1,
    deliveryType: false,
    customersPaymentInfo: [{
        amount: 1,
        payment: defaultPaymentType,
        paid: false,
        paidAmount: 0,
        change: 0,
        ssn: "",
        companyName: "",
        name: "",
        phone: ""
    }]
};

const initialPaymentInfo = (state) => {
    const ssn = (state.invoice) ? state.customersPaymentInfo[0].ssn : "";
    const companyName = (state.invoice) ? state.customersPaymentInfo[0].companyName : "";
    return [{
        amount: state.totalPrice,
        payment: defaultPaymentType,
        paid: false,
        paidAmount: 0,
        change: 0,
        ssn: ssn,
        companyName: companyName,
        name: state.customersPaymentInfo[0].name,
        phone: state.customersPaymentInfo[0].phone
    }];
}

const sumAndDiscount = (state) => {
    state.sum = Math.ceil(getTotalSum(state.items));
    state.discountPrice = Math.ceil(discountPrice(state));
    state.totalPrice = state.sum - state.discountPrice;
    state.customers = 1;
    state.customersPaymentInfo = initialPaymentInfo(state);
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        addCartItem: (state, {payload}) => {
            const data = payload.item;
            const product = new Product(data.product);
            product.setPrice(payload.pizzaPrices, data.size);
            product.setSizeTitle(payload.pizzaPrices, data.size);

            state.items.push(new OrderItem({
                id: product.id,
                toppings: data.selected ? data.selected : [],
                count: data.count ? data.count : 1,
                size: data.size ? data.size : null,
                product,
                type: payload.type ? payload.type : false,
            }));
            sumAndDiscount(state);
        },
        addCartComboItem: (state, {payload}) => {
            state.items.push(new OrderComboItem({
                combo: {...payload.combo},
                items: payload.items
            }));
            sumAndDiscount(state);
        },
        editCartItem: (state, {payload}) => {
            state.currentItem = payload.idx;
            state.currentTypeItem = payload.type;
        },
        updateCartItem: (state, {payload}) => {
            const data = payload.item;
            const product = new Product(data.product);
            product.setPrice(payload.pizzaPrices, data.size);
            product.setSizeTitle(payload.pizzaPrices, data.size);
            state.items[state.currentItem] = new OrderItem({
                id: product.id,
                toppings: data.selected ? data.selected : [],
                count: data.count,
                size: data.size,
                product,
                type: payload.type ? payload.type : false
            });
            sumAndDiscount(state);
        },
        updateCartComboItem: (state, {payload}) => {
            state.items[state.currentItem] = new OrderComboItem({
                combo: payload.combo,
                items: payload.items
            });
            sumAndDiscount(state);
        },
        addCustomer: (state) => {
            state.customers++;
            state.customersPaymentInfo = arrCustomers(state.customers, state.totalPrice);
        },
        removeCustomer: (state) => {
            if (state.customers !== 1) {
                state.customers--;
                state.customersPaymentInfo = arrCustomers(state.customers, state.totalPrice);
            }
        },
        changeCustomerAmount: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].amount = payload.amount;
        },
        changeCustomerPayment: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].payment = payload.payment;
        },
        changeCustomerPaid: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].paid = payload.paid;
            state.customersPaymentInfo[payload.idx].paidAmount = payload.paidAmount;
            state.customersPaymentInfo[payload.idx].change = payload.change;
        },
        changeCustomerType: (state) => {
            state.invoice = !state.invoice;
            state.customers = 1;
            state.customersPaymentInfo = initialPaymentInfo(state);
        },
        changeDeliveryType: (state, {payload}) => {
            state.deliveryType = !state.deliveryType;
        },
        changeCustomerSsn: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].ssn = payload.ssn;
        },
        changeCustomerCompanyName: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].companyName = payload.companyName;
        },
        changeCustomerData: (state, {payload}) => {
            if (payload.name !== undefined)
                state.customersPaymentInfo[payload.idx].name = payload.name;
            if (payload.phone !== undefined)
                state.customersPaymentInfo[payload.idx].phone = payload.phone;
        },
        changeDiscountData: (state, {payload}) => {
            if (payload.discount !== undefined)
                state.discount = payload.discount.length === 0 ? null : +payload.discount;
            if (payload.employer !== undefined)
                state.employer = payload.employer.length === 0 ? null : payload.employer;
            sumAndDiscount(state);
        },
        removeCartItem: (state, {payload}) => {
            state.items.splice(payload.idx, 1);
            sumAndDiscount(state);
        },
        clearCart: () => initialState
    },
    extraReducers: {
        ['persist/REHYDRATE']: (state, {payload}) => {
            if (payload && payload.cart) {
                state.items = payload.cart.items.map((item) => {
                    if (item.combo) {
                        return new OrderComboItem({
                            combo: item.combo,
                            items: item.items
                        })
                    }
                    return new OrderItem(item);
                });
                state.currentItem = false;
                state.currentTypeItem = false;
                state.invoice = false;
                state.deliveryType = false;
                state.customers = payload.cart.customers;
                state.discount = payload.cart.discount;
                state.employer = payload.cart.employer;
                state.customersPaymentInfo = payload.cart.customersPaymentInfo;
                state.sum = getTotalSum(state.items);
                state.discountPrice = discountPrice(state);
                state.totalPrice = state.sum - state.discountPrice;
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    addCartItem,
    addCartComboItem,
    editCartItem,
    updateCartItem,
    updateCartComboItem,
    removeCartItem,
    clearCart,
    addCustomer,
    removeCustomer,
    changeCustomerAmount,
    changeCustomerPayment,
    changeCustomerPaid,
    changeCustomerType,
    changeCustomerSsn,
    changeCustomerCompanyName,
    changeCustomerData,
    changeDiscountData,
    changeDeliveryType
} = cartSlice.actions

export default cartSlice.reducer