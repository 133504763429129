import {createSlice} from '@reduxjs/toolkit'
import {arrCustomers} from "../helpers/order";
import {defaultPaymentType} from "../data";

interface PayState {
    id: number,
    sum: number,
    invoice: boolean,
    customers: number,
    receiptUrl: ?string,
    customersPaymentInfo: []
}

const initialState: PayState = {
    id: null,
    sum: 0,
    invoice: false,
    receiptUrl: null,
    customers: 1,
    customersPaymentInfo: [{
        amount: 1,
        payment: defaultPaymentType,
        paid: false,
        paidAmount: 0,
        change: 0,
        ssn: "",
        companyName: "",
        name: "",
        phone: ""
    }]
};

const initialPaymentInfo = (state) => {
    const ssn = (state.invoice) ? state.customersPaymentInfo[0].ssn : "";
    const companyName = (state.invoice) ? state.customersPaymentInfo[0].companyName : "";
    return [{
        amount: state.sum,
        payment: defaultPaymentType,
        paid: false,
        paidAmount: 0,
        change: 0,
        ssn: ssn,
        companyName: companyName,
        name: state.customersPaymentInfo[0].name,
        phone: state.customersPaymentInfo[0].phone
    }];
}

export const paySlice = createSlice({
    name: 'pay',
    initialState: initialState,
    reducers: {
        setData: (state, {payload}) => {
            state.id = payload.id;
            state.sum = payload.amount;
            state.receiptUrl = payload.receiptUrl;
            state.customersPaymentInfo = [{
                amount: payload.amount,
                payment: defaultPaymentType,
                paid: false,
                paidAmount: 0,
                change: 0,
                ssn: "",
                companyName: "",
                name: "",
                phone: ""
            }];
        },
        addCustomer: (state) => {
            state.customers++;
            state.customersPaymentInfo = arrCustomers(state.customers, state.sum);
        },
        removeCustomer: (state) => {
            if (state.customers !== 1) {
                state.customers--;
                state.customersPaymentInfo = arrCustomers(state.customers, state.sum);
            }
        },
        changeCustomerAmount: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].amount = payload.amount;
        },
        changeCustomerPayment: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].payment = payload.payment;
        },
        changeCustomerPaid: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].paid = payload.paid;
            state.customersPaymentInfo[payload.idx].paidAmount = payload.paidAmount;
            state.customersPaymentInfo[payload.idx].change = payload.change;
        },
        changeCustomerType: (state) => {
            state.invoice = !state.invoice;
            state.customers = 1;
            state.customersPaymentInfo = initialPaymentInfo(state);
        },
        changeCustomerSsn: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].ssn = payload.ssn;
        },
        changeCustomerCompanyName: (state, {payload}) => {
            state.customersPaymentInfo[payload.idx].companyName = payload.companyName;
        },
        clearCart: () => initialState
    },
})

// Action creators are generated for each case reducer function
export const {
    clearCart,
    addCustomer,
    removeCustomer,
    changeCustomerAmount,
    changeCustomerPayment,
    changeCustomerPaid,
    changeCustomerType,
    changeCustomerSsn,
    changeCustomerCompanyName,
    setData
} = paySlice.actions

export default paySlice.reducer