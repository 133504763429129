import Typography from "@mui/material/Typography";
import {Box, FormControl, TextField} from "@mui/material";
import * as React from "react";
import {useDispatch} from "react-redux";
import {changeCustomerData} from "../../store/cart-slice";
import Discount from "./discount";
import {totalPrice} from "../../helpers/order";

const Phone = ({cart, focus, setFocus}) => {
    const dispatch = useDispatch();
    const handleChangeData = (idx, field) => (event) => {
        const payload = {};
        payload[field] = event.target.value;
        dispatch(changeCustomerData({idx, ...payload}))
    };

    return (
        <div>
            <Typography variant="h6" component="h6" sx={{ml: 2}}>
                {totalPrice(cart)}
            </Typography>
                <Box sx={{mt: 2, ml: 2}} key={`phn-0`}>
                    <FormControl sx={{m: 1, width: 200}} variant="outlined">
                        <TextField
                            id={`outlined-adornment-weight-user-name`}
                            aria-describedby="outlined-adornment-weight-user-name"
                            value={cart.customersPaymentInfo[0].name}
                            onChange={handleChangeData(0, 'name')}
                            label="Name"
                            required={true}
                        />
                    </FormControl>
                    <FormControl sx={{m: 1, width: 265}} variant="outlined">
                        <TextField
                            id={`outlined-adornment-user-phone`}
                            aria-describedby="outlined-adornment-user-phone"
                            value={cart.customersPaymentInfo[0].phone}
                            onChange={handleChangeData(0, 'phone')}
                            required={true}
                            label="Phone"
                        />
                    </FormControl>
                </Box>
        </div>
    );
}

export default Phone;